export const no = {
  login: "LOGG INN",
  logout: "LOGG UT",
  home: "HJEM",
  home_description_01:
    "Inviter alle du trenger til prosjektene dine på en enkel måte, og tilbyr tre forskjellige brukertilgangsnivåer: Admin, User eller Guest.",
  home_description_02:
    "Salus Scaffold ønsker å være et verktøy for stillasindustrien, og tilbyr presis kontroll over HMS-standarder, stillasmengder og mer. Vi er dedikert til å lage løsninger som samsvarer med behovene til deg og din bedrift.",
  aboutUs: "OM OSS",
  services: "TJENESTER",
  contactUs: "KONTAKT OSS",
  support: "STØTTE",
  project: "PROSJEKT",
  "hero-title": "SALUS STILLASLØSNINGER",
  "hero-description":
    "Et brukervennlig system designet for stillasindustrien, som sikrer sikkerhet og kvalitet.",
  "hero-button_01": "Mine prosjekter",
  "hero-button_02": "Opprett Prosjekt",
  "aboutUs-description":
    "Hos Salus Scaffold er vi dedikert til å revolusjonere stillasindustrien gjennom intuitiv teknologi og dyp bransjekunnskap. Målet vårt er å gi stillasbyggerne moderne verktøy som forenkler komplekse oppgaver og prioriterer sikkerhet.",
  "aboutUs-button_01": "Les Mer",
  "demo-heading_01": "EN ENKEL OG SIKKER MÅTE Å GODKJENNE ET STILLAS",
  "demo-heading_02":
    "Et intuitivt og pålitelig kvalitetssystem designet for bygg- og anleggsbransjen, som sikrer sikkerhet og forutsigbarhet.",
  "demo-button": "Begynn Bruken",
  "our-service-title": "VÅRE TJENESTER",
  "our-service-1": "Materiellister",
  "our-service-1-description":
    "Legg inn dine egne komponenter eller velg fra våre eksisterende lister for å få en oversikt over vekten eller mengden av komponenter på tvers av prosjektene dine. Du kan enkelt overføre materiallister til de andre prosjektene dine også.",
  "our-service-2": "Skjemaer",
  "our-service-2-description":
    "Våre godkjenningsskjemaer lar deg legge inn generell informasjon og legge ved bilder for dokumentasjonsformål. Sjekklister fylles ut for å sikre at stillasene er i forskriftsmessig stand, med fleksibiliteten til å enkelt legge til punkter ettersom vi forstår at stillasene tjener forskjellige formål.",
  "our-service-2-1":
    "Etterkontroll er nå enklere enn noen gang med skjemaer som viser alle dine aktive stillaser sammen med en sjekkliste for visuell inspeksjon. I tillegg er det enkelt å finne spesifikke steder, enten ved beskrivelse eller bilde, siden stillaser lett kan skjule seg på store prosjekter.",
  "our-service-3": "Observasjoner",
  "our-service-3-description":
    "Avvikshåndtering med varsler, enten det er en uønsket hendelse eller en positiv observasjon, gjør det enkelt å legge inn handlinger og bildefunksjon.",
  "our-service-4": "Sikkerhetsanalyse (SJA)",
  "our-service-4-description":
    "Det er viktig å forstå oppgaven din. Vi deler opp jobber i underoppgaver, og gir en bedre forståelse av de ulike oppgavene som er involvert, enten det er utstyrstransport, pakking, kraning, montering eller demontering. Du kan legge inn risiko knyttet til hver oppgave.",
  "faq-title": "OFTEN STILTE SPØRSMÅL",
  "faq-1": "Hva er Salus Scaffold?",
  "faq-2":
    "Hvordan hjelper Salus Scaffold meg med å administrere stillasprosjekter?",
  "faq-3": "Kan jeg invitere teamet mitt til å bruke Salus Scaffold?",
  "faq-4": "Er dataene mine sikre på Salus Scaffold?",
  "faq-5": "Hvor mye koster det å bruke Salus Scaffold?",
  faq_6: "Kontakt oss",
  "faq-1-ans":
    "Salus Scaffold er en digital plattform utviklet for å hjelpe stillasbedrifter med å administrere prosjekter effektivt. Den inkluderer funksjoner som stillasgodkjenningsskjemaer, risikovurderinger, materialsporing og teamledelse, og tilbyr en omfattende løsning skreddersydd spesielt for stillasindustrien.",
  "faq-2-ans":
    "Salus Scaffold tilbyr verktøy som prosjektplanlegging, stillasloggsporing, HMS-rapporter og etterkontrollsjekklister. Det lar deg strømlinjeforme planleggings-, godkjennings- og dokumentasjonsprosessene samtidig som du holder prosjektdataene dine organisert og lett tilgjengelig.",
  "faq-3-ans":
    "Ja! Salus Scaffold lar deg invitere brukere til prosjektene dine med forskjellige tilgangsnivåer. Du kan tilordne roller som administrator, bruker eller gjest, noe som gir deg full kontroll over hvem som kan se, redigere eller godkjenne prosjektdetaljer.",
  "faq-4-ans":
    "Absolutt. Vi prioriterer ditt personvern og datasikkerhet. Salus Scaffold bruker krypterte servere og sikre autentiseringsprotokoller for å sikre at alle dine prosjektdata og personopplysninger er beskyttet.",
  "faq-5-ans":
    "Salus Scaffold tilbyr fleksible abonnementsplaner basert på størrelsen på teamet ditt. Vi tilbyr månedlige og årlige planer for forskjellige brukerlag, inkludert alternativer for enkeltbrukere, små team og større bedrifter. Du kan også dra nytte av vårt livstidsmedlemskap for tidlig adoptere til en rabattert pris.",
  product: "PRODUKTER",
  "quality-system": "Kvalitetssystem",
  "central-approval": "Sentral Godkjenning",
  "HSE-course": "HMS Kurs",
  contact: "KONTAKT",
  "follow-us": "FØLG OSS",
  "PROVIDING-CONSTRUCTIONS":
    "TILBY TJENESTER OG KONSTRUKSJONER AV BESTE KVALITET",
  "We-provide-easier":
    "Vi leverer et digitalt system designet spesielt for bygg- og anleggsbransjen. Systemet vårt effektiviserer og digitaliserer daglig drift gjennom effektiv prosjektstyring, avvikssporing, tidsregistrering og prosedyreforbedringer, noe som gjør arbeidet ditt enklere.",
  SaveTime: "Spare tid",
  SaveTimeCompany:
    "Spar tid når du registrerer materialbevegelser mellom byggeplasser og byggeplasser i din bedrift",
  MaterialOnTime: "Materiale i tide",
  EnsureThatTheTime:
    "Sørg for at riktig materiale i nødvendig mengde er på byggeplassen din i tide.",
  Transparency: "Åpenhet",
  IncreaseTheMovements:
    "Øk åpenheten til aksjene dine og materielle bevegelser.",
  Utilization: "Utnyttelse",
  IncreaseTheMaterials: "Øk utnyttelsen av egne og leide materialer.",
  ourClient: "VÅRE KLIENTER",
  meetTheTeam: "MØT TEAMET",
  clientReviews: "KLIENTANMELDELSER",
  oliverMeyer: "Oliver Meyer",
  academicDirector: "faglig leder",
  description:
    "Jeg hadde nylig privilegiet av å jobbe med Salus Scaffold Solutions for et byggeprosjekt, og jeg kan ikke uttrykke hvor imponert jeg er over deres tjenester. Fra det øyeblikket vi nådde ut for et tilbud til fullføringen av prosjektet, demonstrerte de et enestående nivå av profesjonalitet, ekspertise og engasjement for sikkerhet. Først og fremst var stillasoppsettet deres upåklagelig. Teamet hos Salus Stillas Solutions sørget for at byggeplassen vår ikke bare var tilgjengelig, men også sikker. Deres oppmerksomhet på detaljer ved montering og inspeksjon av stillaset var virkelig prisverdig, og garanterte sikkerheten til alle på stedet.",
  createYourProjectHere: "LAG PROSJEKTET DITT HER",
  searchProjects: "Søk etter prosjekter",
  loading: "Laster inn...",
  activeProject: "Aktivt prosjekt",
  completedProject: "Fullført prosjekt",
  inactive: "Inaktiv",
  whatWeDo: "HVA VI GJØR",
  atSalusStillas:
    "Hos Salus Scaffold er vårt primære mål å levere høykvalitets og sikre tjenester til våre kunder ved å tilby en digital plattform for omfattende styring av alt stillasarbeidet ditt. Vi er dedikerte til å fremme bransjen gjennom fortreffelighet.",
  timeRegistration: "Tidsregistrering",
  checklists: "Sjekklister",
  forms: "Skjemaer",
  observations: "Observasjoner",
  procedures: "Prosedyrer",
  orders: "Bestillinger",
  pictures: "Bilder",
  filers: "Filer",
  inspections: "Inspeksjoner",
  weHelpYou: "VI HJELPER DEG MED Å STYRE ALT STILLASARBEID",
  "Let’sWorkTogether": "La oss jobbe sammen",
  advancingConstruction: "AVANSERENDE BYGGEINDUSTRI",
  deliveringHighQuality:
    "Vi er dedikerte til å forbedre byggeprosessene. Gjennom vår digitale plattform tilbyr vi sikre tjenester av topp kvalitet, noe som gjør stillasarbeid enklere å administrere.",
  getAFreeDemo: "FÅ EN GRATIS DEMO",
  pleaseFillTheBelowForm:
    "Fyll ut skjemaet nedenfor for å få informasjon om våre produkter eller tjenester",
  companyName: "selskapsnavn",
  yourFullName: "Ditt fulle navn",
  mobileNumber: "Mobilnummer",
  mailId: "E-post-ID",
  privacyPolicy:
    "Vi krever denne informasjonen for å nå ut til deg og gi deg informasjon om våre produkter og tjenester. Vær trygg, vi prioriterer personvernet ditt, og informasjonen din holdes helt sikker hos oss. Du kan se gjennom vår",
  privacyPolicy1: "Personvernerklæring",
  and: "og",
  "terms&Conditions": "Betingelser og vilkår",
  here: "her.",
  getDemo: "Få demo",
  getInTouchWithUs: "TA KONTAKT MED OSS",
  hereYouWillFind: "Her finner du vår kontaktinformasjon",
  makeACall: "Ta en telefon",
  sendAnEmail: "Send en epost",
  locateUs: "Finn oss",
  "salus@stillas.com": "salus@stillas.com",
  dummyAddress:
    "101, Dummy-adresse, plassering, områdenavn, by, land - PIN-kode",
  contactForm: "KONTAKT SKJEMA",
  pleaseFillTheBelow: "Fyll ut skjemaet nedenfor for å komme i kontakt",
  WhatIsThe: "Hva handler forespørselen om?",
  select: "Plukke ut",
  unitedStates: "forente stater",
  canada: "Canada",
  france: "Frankrike",
  germany: "Tyskland",
  additionalInformation: "Ytterligere informasjon",
  writeYour: "Skriv kommentarene dine her...",
  followUpOn: "FØLG OSS PÅ",
  salusStillasSolutions: "Salus Stillas Solutions",
  salusStillas: "Salus_Stillas",
  bestSolutionFor: "BESTE LØSNING FOR BYGGEINDUSTRIEN",
  howCanWeHelpYou: "HVORDAN KAN VI HJELPE DEG I DAG?",
  searchArticles: "Søk i artikler",
  getStarted: "Kom i gang",
  generalQuestions: "Generelle spørsmål",
  projects: "Prosjekter",
  hours: "Timer",
  personal: "Personlig",
  company: "Selskap",
  mobileVersion: "Mobil versjon",
  integrations: "Integrasjoner",
  question: "Slik kommer du i gang med HMS- og Kvalitetssystemet som ny kunde",
  answer:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Heltall nec mattis risus. Aenean pulvinar quis nulla vitae ornare. Interdum et malesuada fames ac ante ipsum primis in faucibus. Heltall quis mauris vel dolor sodales kurs et et massa. Maecenas sed ullamcorper est. Nullam rhoncus at velit mollis porta. Maecenas maximus sollicitudin diam bibendum pretium.Donec sem mi, rhoncus vel dolor eu, viverra bibendum enim. Suspendisse ante leo, sagittis vitae urna in, accumsan viverra purus. Vivamus molestie sit amet nisi ut venenatis. Curabitur rutrum sollicitudin felis i lacinia. Donec ut velit dui. In venenatis mi sed nisi consectetur sagittis. Sed molestie consequat dictum. Cras vitae mauris nisl. Pellentesque dignissim, massa facilisis lobortis sollicitudin, ante orci rhoncus arcu, vel malesuada lectus tellus eget sem. Vestibulum a hendrerit felis. Curabitur malesuada vehicula arcu, dictum vulputate eros congue vitae. Mauris blandit augue in neque blandit, in egestas elit efficitur. Interdum et malesuada fames ac ante ipsum primis in faucibus. Aenean euismod diam ut nibh molestie laoreet. Pellentesque lacus ligula, tristique eget lobortis nec, blandit at sapien. Praesent ac molestie nulla. Donec vehicula odio ut enim tincidunt semper. Sed malesuada ultricies lectus. Ut ligula magna, vulputate in congue non, egestas vitae massa. Sed consequat maximus porttitor. Aliquam tincidunt non ipsum semper dictum. Cras felis massa, scelerisque nec dolor ut, tempus commodo eros. Proin suscipit ex a diam aliquet, sed plassert som interdum. Ut blandit velit sed tellus ornare, eu ullamcorper ex fringilla. Suspendisse rutrum egestas ante. Du er en aliquet est. Cras eu fringilla risus. Nunc eu lacus fringilla, volutpat quam fermentum, egestas massa. Fusce aliquam ligula nunc, eu laoreet sem convallis nec.",
  question2: "Har kvalitetskontroll funksjonene vi trenger?",
  enterProjectName: "Skriv inn prosjektnavn",
  projectNumber: "Prosjektnummer:",
  uploadBackground: "Last opp bakgrunn",
  companyDetails: "FIRMA DETALJER",
  organizationNumber: "Organisasjonsnummer",
  invoiceAddress: "Faktura-adresse",
  contactPerson: "Kontaktperson",
  emailId: "Epost id",
  phoneNumber: "Telefonnummer",
  otherCompanyDetails: "Andre firmadetaljer",
  projectDetails: "PROSJEKTDETALJER",
  address: "Adresse",
  otherProjectDetails: "Andre prosjektdetaljer",
  createProject: "LAG PROSJEKT",
  "createForm/EditForm": "LAG SKJEMA / REDIGER SKJEMA",
  editYourProject: "Rediger prosjektet ditt her eller lag skjema",
  viewBackground: "Se bakgrunn",
  invite: "Invitere",
  shareThisProject: "DEL DETTE PROSJEKTET",
  sending: "Sender...",
  shareThisProjectViaEmail:
    "Del dette prosjektet via e-post, og velg om mottakeren bare kan se eller redigere det.",
  writeEmailHere: "Skriv e-post her",
  canView: "Kan se",
  canEdit: "Kan redigere",
  admin: "Admin",
  status: "Status:",
  createForm: "LAG SKJEMA",
  approvalForm: "Godkjenningsskjema",
  scaffoldForm: "Stillasskjema",
  scaffold: "Scaffold",
  projectScaffoldForm: "Prosjektstillasskjema",
  createScaffoldForm: "Lag stillasskjema",
  observation: "Observasjon",
  observationDetails: "Observasjonsdetaljer",
  observationDescription: "Observasjonsbeskrivelse",
  observerDetail: "Observatørdetaljer",
  materialList: "Materialliste",
  files: "Filer",
  sJA: "Safe-Job-Analysis (SJA)",
  editProject: "REDIGER PROSJEKT",
  safeJobAnalysis: "Trygg jobbanalyse",
  projectSafeJOb: "PROSJEKTSIKR JOBBANALYSE",
  createSafeJobAnalysis: "Lag sikker jobbanalyse",
  recentAnalysisList: "Nylig analyseliste",
  analysisListName: "Analyselistenavn",
  lastEdit: "Siste redigering",
  members: "Medlemmer",
  addComment: "Legg til en kommentar",
  editComment: "Rediger kommentar",
  commenting: "Kommenterer...",
  delete: "Slett",
  edit: "Redigere",
  save: "Lagre",
  jobAnalysis: "JOBB ANALYSE",
  projectName: "prosjektnavn",
  dateOfAnalysis: "Analysedato",
  "job/TaskDescription": "Jobb/ Oppgavebeskrivelse",
  hazardsAndRisks: "Farer og risikoer",
  safetyMeasures: "Sikkerhetstiltak",
  personalProtectiveEquipment: "Personlig verneutstyr (PPE)",
  "sequenceOfJob/Task": "Rekkefølge av jobb/oppgave",
  responsibleWorkers: "Ansvarlige arbeidere",
  clearSignature: "Tydelig signatur",
  addPerson: "Legg til person",
  dateOfApproval: "DATO FOR GODKJENNING:",
  signature: "SIGNATUR:",
  "customer’sSignature": "Kundens signatur",
  AddSignature: "Legg til signatur",
  signatureOfTheApprover: "Godkjennerens underskrift",
  distribution: "FORDELING:",
  customerName: "Kundenavn",
  otherRelevantParties: "Andre relevante parter",
  sendforReview: "Send til vurdering",
  EditSafeJobAnalysis: "Rediger sikker jobbanalyse",
  material: "MATERIALE",
  projectMaterialList: "PROSJEKTMATERIALLISTE",
  createMaterialList: "Lag materialliste",
  recentMaterialList: "Nylig materialliste",
  materialListName: "Materiallistenavn",
  priceManager: "prissjef",
  ourServices: "Våre tjenester",
  listOfMaterials: "LISTE OVER MATERIALER",
  CustomerContactPerson: "Kunde Kontakt Person",
  materialsOut: "Materialer ut",
  materialsIn: "Materialer i",
  signatureDeliveryResponsible: "Den ansvarliges signatur",
  signatureOfTheCustomer: "Kundens signatur",
  selectScaffoldingSystem: "Velg Stillassystem",
  createCustomList: "Opprett egendefinert liste",
  selectTheTypeOfScaffolding: "Velg typen stillassystem du vil bruke:",
  productNo: "Produktnr.",
  productName: "Produktnavn",
  descriptionName: "Beskrivelse",
  weightInKG: "Vekt (i kg)",
  date: "Dato",
  category: "Kategori",
  totalWeight: "Total vekt:",
  listOfSelectedMaterials: "Liste over utvalgte materialer",
  update: "Oppdater",
  createYourCustomList: "Lag din egendefinerte liste",
  productDescription: "produktbeskrivelse",
  enterWeightInKG: "Angi vekt (i kg)",
  enterQuantity: "Angi mengde",
  add: "Legg til",
  editMaterialList: "Rediger MATERIALLISTE",
  signatureOfTheDeliveryBoy: "Signaturen til den ansvarlige",
  projectObservationList: "PROSJEKTOBSERVASJONSLISTE",
  createObservationList: "Lag observasjon",
  "observation-title": "observasjonstittel",
  "What-Is-Observed": "Hva blir observert ?",
  observationFrom: "Observasjon fra",
  observationDate: "Observasjonsdato",
  observedDetail: "Observert detalj",
  actionTaken: "Tiltak",
  responsibleParty: "Ansvarlig fest",
  editObservation: "Rediger observasjon",
  upload: "Laste opp",
  recentPictures: "Nylige bilder",
  searchFiles: "Søk etter filer",
  viewMore: "Se mer",
  recentFiles: "Nylige filer",
  formDatas: "NYLIGE SKJEMADETALJER",
  ScaffoldingForm: "Stillaset skjema",
  ScaffoldingItems: "Stillas elementer",
  ScaffoldingWeight: "Stillasvekt",
  Observations: "Observasjoner",
  SJA: "SJA",
  approve: "VEDTA",
  projectApproveForm: "PROSJEKTGODKJENNINGSSKJEMA",
  searchApprovalForm: "Søkegodkjenningsskjema",
  createApprovalForm: "LAG GODKJENNINGSSKJEMA",
  viewAfterControls: "Etter kontrollskjema",
  scaffoldDetail: "STILLASDETALJER:",
  "scaffoldName/Type": "Stillas navn / type",
  WorkOrderNumber: "Arbeidsordrenummer",
  specificLocation: "Spesifikk lokasjon",
  dateOfInspection: "Dato for inspeksjon",
  generalInformation: "GENERELL INFORMASJON:",
  sameAsAfterControlForm: "Samme som etter kontrollskjema",
  scaffolderOwner: "Stillas eier",
  inspectedBy: "Inspisert av",
  builtBy: "Bygd av",
  userResponsible: "Brukeransvarlig",
  maximumWeightPerm2Inkilograms: "maksimal vekt per m2 i kilo",
  selectScaffoldClass: "Velg Stillasklasse",
  WallAnchorsCapacity: "Kapasitet for veggankre (i KN eller KG)",
  amountOfWallAnkers: "Mengde Wall Ankers",
  sizeOfTheScaffold: "Størrelse på stillaset",
  visualInspection: "VISUELL INSPEKSJON:",
  areThereAnySpecific: "ER DET NOEN SPESIFIKKE BETINGELSER ELLER AVTALER",
  comments: "KOMMENTARER:",
  writeHere: "Skriv her...",
  signatureOfTheInspector: "Inspektørens underskrift",
  applyForApproval: "Søk om godkjenning",
  controlForm: "Kontrollskjema",
  editYourScaffoldHere: "REDIGERING AV STILLASET HER",
  download: "nedlasting",
  editForm: "Rediger skjema",
  convertToAfterControlForm: "Konverter til etterkontrollskjema",
  loginSuccessfully: "Logg på vellykket",
  pleaseEnterEmail: "Vennligst skriv inn e-post",
  pleaseEnterPassword: "Skriv inn passord",
  anErrorOccurredDuringLogin: "Det oppsto en feil under pålogging",
  logInToYourAccount: "Logg inn på kontoen din",
  email: "E-post",
  password: "Passord",
  forgotPassword: "Glemt passord?",
  dontHaveAnAccount: "Har du ikke en konto?",
  registerHere: "Registrer her",
  privacyPolicySignin: "Personvernerklæring",
  termsOfUse: "Vilkår for bruk.",
  logoutSuccessfully: "Logg ut vellykket",
  pleaseEnterUserName: "Vennligst skriv inn brukernavn",
  registeredSuccessFully: "Vellykket registrert",
  pleaseFillTheDetailsBelowToRegister:
    "Fyll ut detaljene nedenfor for å registrere deg",
  whatDescribesYouBetter: "Hva beskriver deg bedre?",
  projectManager: "Prosjektleder",
  user: "Bruker",
  customer: "Kunde",
  next: "Neste",
  haveAnAccount: "Har en konto?",
  loginHere: "Logg inn her",
  fullName: "Fullt navn",
  iUnderstandAndAgreeTo: "Jeg forstår og godtar",
  createAccount: "Opprett konto",
  PleaseEnterCompanyName: "Vennligst skriv inn firmanavn",
  pleaseEnterYourFullName: "Vennligst oppgi ditt fulle navn",
  pleaseEnterMobileNumber: "Vennligst skriv inn mobilnummer",
  pleaseEnterAvalidEmail: "Vennligst skriv inn en gyldig e-postadresse",
  pleaseEnterYourEmail: "Vennligst skriv inn e-postadressen din",
  projectNameIsRequired: "Prosjektnavn er påkrevd",
  companyNameisRequired: "Firmanavn er påkrevd",
  organizationNumberIsRequired: "Organisasjonsnummer er påkrevd",
  emailIsRequired: "E-post er påkrevd",
  pleaseEnterValidEmail: "Vennligst skriv inn gyldig e-post!",
  phoneNumberIsRequired: "Telefonnummer er påkrevd",
  invoiceAddressIsrequired: "Fakturaadresse er påkrevd",
  contactPersonIsRequired: "Kontaktperson er nødvendig",
  addressIsRequired: "Adresse er påkrevd",
  startWith4LettersFollowedBy4Digits:
    "start med 4 bokstaver etterfulgt av 4 sifre",
  projectNumberCannotBeMoreThan8CharactersLong:
    "Prosjektnummer kan ikke være mer enn 8 tegn langt",
  projectUpdatedSuccessfully: "Prosjektet ble oppdatert",
  failedToUpdateProject: "Kunne ikke oppdatere prosjektet",
  invalidEmailFormat: "Ugyldig e-postformat",
  pleaseSelectAPermissionLevel: "Velg et tillatelsesnivå",
  projectInvitedSuccessfully: "Prosjekt invitert vellykket",
  projectCreatedSuccessfully: "Prosjekt opprettet vellykket",
  nameIsRequired: "Navn er påkrevd",
  dateIsRequired: "Dato er påkrevd",
  controlFormCreatedSuccessfully: "Kontrollskjema opprettet",
  errorInCreatingControlForm: "Feil under oppretting av kontrollskjema",
  signatureIsCompulsoryToProcess: "Signatur er obligatorisk å behandle",
  anErrorOccurredWhileSubmittingTheForm:
    "Det oppsto en feil under innsending av skjemaet",
  scaffoldNameIsRequired: "Stillasnavn er påkrevd",
  "scaffoldIdentification/NumberIsRequired":
    "Stillasidentifikasjon/-nummer er påkrevd",
  scaffolderOwnerIsRequired: "Stillaseier er påkrevd",
  inspectedByIsRequired: "Inspisert av er påkrevd",
  builtByIsRequired: "Bygget av kreves",
  userResponsibleIsRequired: "Brukeransvarlig kreves",
  locationIsRequired: "Plassering er påkrevd",
  scaffoldClassIsRequired: "Stillasklasse er påkrevd",
  totalWeightPerM2MustBeNumber: "Totalvekt pr. M2 må være nummer",
  totalWeightPerM2IsRequired: "Totalvekt per M2 kreves",
  amountOfWallAnkersMustBeNumber: "Antall Wall Ankers må være nummer",
  amountOfWallAnkersIsRequired: "Mengde Wall Ankers er påkrevd",
  wallAnchorsCapacityMustBeANumber: "Veggankeres kapasitet må være et tall",
  wallAnchorsCapacityIsRequired: "Kapasitet for veggankre er nødvendig",
  sizeOfTheScaffoldMustBeANumber: "Størrelsen på stillaset må være et tall",
  szeOfTheScaffoldIsRequired: "Størrelse på stillaset er påkrevd",
  updatedsuccessfully: "Oppdatert vellykket",
  signatureICompulsoryToProcess:
    "Inspektørens underskrift er obligatorisk å behandle",
  approvalFormCreatedSuccessfully: "Godkjenningsskjema opprettet",
  checkAboveInformation: "Sjekk informasjonen ovenfor",
  anErrorOccurredWhileProcessingTheApprovalForm:
    "Det oppsto en feil under behandling av godkjenningsskjemaet",
  productNameIsRequired: "Produktnavn er obligatorisk",
  weightMustBeANumber: "vekt må være et tall",
  weightIsRequired: "vekt er nødvendig",
  weightMustBUnder1: "vekt må være under 1",
  customerNameCodeIsRequired: "kundenavnkode er påkrevd",
  materialListNameIsRequired: "Materiallistenavn er påkrevd",
  projectDateIsRequired: "prosjektdato er påkrevd",
  transPorIsRequired: "transport er nødvendig",
  saveSuccessfully: "Lagre vellykket",
  signatureIsCompulsoryToProceeds: "Underskrift er obligatorisk for inntektene",
  updateSuccessfully: "Oppdatering vellykket",
  saveDataIsCompulsoryToProceeds: "Lagre data er obligatorisk for inntektene",
  observationNameIsRequired: "Observasjonsnavn er påkrevd",
  observationDateIsRequired: "Observasjonsdato er påkrevd",
  observedDetailIsRequired: "Observert detalj er nødvendig",
  customerNameIsRequired: "Kundenavn er påkrevd",
  dateOfApprovalIsRequired: "Godkjenningsdato kreves",
  observationFormCreatedSuccessfully: "Observasjonsskjema er opprettet",
  thereIsSomeError: "Det er en feil",
  observationName: "Observasjonsnavn",
  safeJobAnalysisNameIsRequired: "Navn på sikker jobbanalyse er påkrevd",
  dateOfAnalysisIsRequired: "Dato for analyse er påkrevd",
  jobDescriptionIsRequired: "Stillingsbeskrivelse er nødvendig",
  riskIsRequired: "Risiko er nødvendig",
  safetyMeasuresAreRequired: "Sikkerhetstiltak er påkrevd",
  PPEIsRequired: "PPE er påkrevd",
  taskSequenceIsRequired: "Oppgavesekvens er nødvendig",
  customerNameAndSignatureAreRequired: "Kundenavn og signatur kreves",
  approverNameAndSignatureAreRequired: "Godkjennernavn og signatur kreves",
  atLeastOnePersonSignatureIsRequired: "Minst én persons signatur kreves",
  safeJobAnalysisCreatedSuccessfully: "Sikker jobbanalyse er opprettet",
  failedToCreateSafeJobAnalysis: "Kunne ikke opprette sikker jobbanalyse",
  safeJobAnalysisName: "Navn på sikker jobbanalyse",
  observationEditedSuccessfully: "Observasjon Redigert vellykket",
  errorUploadingImagePleaseTryAgain:
    "Feil ved opplasting av bilde. Vær så snill, prøv på nytt.",
  afterControlForm: "Etter kontrollskjema",
  deletedSuccessfully: "slettet",
  afterControlFormList: "Etter kontrollskjemaliste",
  projectNo: "Prosjekt nr.",
  nofileAvailable: "Ingen fil tilgjengelig",
  editAfterControlForm: "REDIGER ETTER KONTROLLSKJEMA",
  yourMessage: "Din beskjed",
  controlFormDetails: "KONTROLLSKJEMADETALJER:",
  recheckResponsibleSignature: "Sjekk ansvarlig signatur på nytt",
  userResponsibleSignature: "Brukeransvarlig signatur",
  approvalFormStatusUpdatedSuccessfully:
    "Godkjenningsskjemastatusen er oppdatert",
  setAsActive: "Sett som aktiv",
  setAsDisassembled: "Sett som demontert",
  setAsInactive: "Angi som inaktiv",
  viewAll: "Se alt",
  viewLess: "se mindre",
  setAsCompleted: "Sett som fullført",
  noProjectAvailable: "Ingen prosjekt tilgjengelig",
  "prevention-of-recurrence": "Forebygging av tilbakefall",
  "save-approval": "Lagre godkjenning",
  "active-scaffolds": "Aktive stillaser",
  "safe-job-analysis": "Sikkerhetsanalyse av jobben",
  "square-meters": "Kvadratmeter (m2)",
  "cubic-meters": "Kubikkmeter (m3)",
  "length-meters": "Lengdemeter (LM)",
  "height-meters": "Høydemeter (HM)",
  ObservationName: "Observasjonsnavn",
  "Here,describetheincidentorobservationindetail":
    " Her, beskriv incidentor observasjon i detalj",
  Describetheactionsthatshouldbetakeninresponsetotheobservation:
    "Beskriv handlingene som bør iverksettes som svar på observasjonen",
  "Specifywhoisresponsibleforimplementingtheactions.":
    "Spesifiser hvem som er ansvarlig for å gjennomføre handlingene.",
  LatestObservations: "Siste observasjoner",
  UnderProgress: " Under arbeid",
  ClosedObservations: "Stengte observasjoner",
  LatestMaterialLists: "Siste materiallister",
  UnderProgessMaterialLists: "Fremdriftsliste",
  closedMaterialLists: "Lukk liste",
  MaterialListName: "Materiallistenavn",
  ViewMore: "Se mer",
  Searchformaterial: "Søk etter materiale",
  SaveForm: "Lagre skjema",
  "Outlinethestep-by-stepsequenceforcompletingthejob/task":
    "Skisser trinn-for-trinn-sekvensen for å fullføre jobben/oppgaven",
  "SpecifyanyrequiredPPEforthisjob/task.":
    "Spesifiser eventuelle nødvendige PPE for denne jobben/oppgaven.",
  Listsafetymeasuresinplaceortobeimplementedtomitigateidentifiedhazards:
    "List opp sikkerhetstiltak som er på plass eller skal implementeres for å redusere identifiserte farer",
  SafeJobAnalysisName: "Navn på sikker jobbanalyse",
  ResponsibleforScaffold: "Ansvarlig for stillas",
  view: "Utsikt",
  Save: "Lagre",
  Admin: "Admin",
  Guest: "Gjest",
  User: "Bruker",
  FromProject: "Fra prosjekt",
  ToProject: "Til prosjekt",
  SAVEPROJECT: "LAGRE PROSJEKT",
  createAfterControlForm: "Opprett etter kontrollskjema",
  editafterControlForm: "Rediger etter kontrollskjema",
  CompletedControlFormList: "Utfylt etter kontrollskjema",
  InprogressControlFormList: "Pågående etter kontrollskjema",
  "ScaffoldName/type": "Stillas Navn/type",
  "ScaffoldID/Number": "Stillas ID/nummer",
  Specificlocation: "Spesifikk lokasjon",
  Control: "Kontrol",
  LastInspection: "Siste inspeksjon",
  AddComment: "Legg til en kommentar",
  VisualInspection: "Visuell inspeksjon",
  Yes: "Ja",
  No: "Nei",
  "Active-scaffolds": "Aktive-stillaser",
  "Inactive-scaffolds": "Inaktive-stillaser",
  Activescaffolds: "Aktive stillaser",
  SubmitData: "Opprett etter kontroll",
  CustomerSignature: "Kundens signatur",
  ApproverName: "Godkjenner navn",
  ApprovalSignature: "Godkjenning Signatur",
  DownloadPDF: "Last ned PDF",
  observationFormEditSuccessfully: "observasjonsskjema Rediger vellykket",
  imageRequired: "Hva er observert? bilde er påkrevd",
  "Yourdiscipline?": "Din disiplin?",
  Observationisrelatedto: "Observasjon er relatert til",
  norelevantcategory: "ingen relevant kategori",
  Orderandtidiness: "Orden og ordens",
  "Physicalworkingenvironment/Ergonomics": "Fysisk arbeidsmiljø/Ergonomi",
  Positivefeedback: "Positive tilbakemeldinger",
  Riskybehavior: "Risikofylt oppførsel",
  "Teamwork/Collaboration": "Teamarbeid/Samarbeid",
  Workmethodorprocedures: "Arbeidsmetode eller rutiner",
  Didyoutakeaction: "Gjorde du grep",
  Describewhatactionyoudid: "Beskriv hva du gjorde",
  "Isthereneedforfurtheraction?": "Er det behov for ytterligere tiltak?",
  "Describewhatfurtheractionyoudid?":
    "Beskriv hvilken ytterligere handling du gjorde",
  Yourname: "Navnet ditt",
  Youremailaddress: "Din epostadresse",
  ViewObservation: "Se observasjon",
  UploadObservation: "Last opp observasjon",
  Uploading: "Laster opp.....",
  Enterotptologinwhichissendtoyouremail:
    "Skriv inn otp for å logge inn som sendes til din e-post",
  EnterOTP: "Skriv inn OTP",
  ResendOTP: "Send OTP på nytt",
  OTPResentSuccessfully: "OTP sendes på nytt",
  "OTP has been sent to your email. Use it to complete the login.":
    "OTP er sendt til din e-post. Bruk den til å fullføre påloggingen.",
  VerifyOTP: "Bekreft OTP",
  ForgetPassword: "Glem passord",
  ChangePassword:"Endre passord",
  ConfirmPassword: "bekreft passord",
  change_password_header: "Skriv inn e-post for å endre passord",
  "Password reset successfully": "Passord tilbakestilt",
  "OTP has been sent to your email. Use it to Forget password.":
    "OTP er sendt til din e-post. Bruk den til å glemme passord.",
  Calender: "Kalender",
  agenda: "dagsorden",
  TeamleaderSignature: "Lagleder Signatur",
  WorkDescription: "arbeid sbeskrivelse",
  AddTask: "Legg til oppgave",
  Add: "Legg til",
  Subtask: "Underoppgave",
  Risk: "Fare",
  Measure: "Mål",
  AddMore: "Legg til mer",
  "Select a Type": "Velg en type",
  "Standard scaffold": "Standard stillas",
  "Fasade scaffold": "Fasade stillas",
  "Hanging scaffold": "Hengende stillas",
  "Rolling scaffold": "Rullestillas",
  "Support scaffolding": "Støtte stillas",
  Other: "Annen",
  ScaffoldName: "Navn på stillaset",
  workDescriptionIsRequired: "Arbeidsbeskrivelse er påkrevd",
  Entervisualinspection: "Gå inn i visuell inspeksjon",
  Class1: "klasse 1",
  Class2: "klasse 2",
  Class3: "klasse 3",
  Class4: "klasse 4",
  Class5: "klasse 5",
  Class6: "klasse 6",
  SelectaUnit: "Velg en enhet",
  AnchorCapacityUnit: "Ankerkapasitetsenhet",
  SizeofScaffold: "Størrelse på stillaset",
  "Writeyourthoughtshere...": "Skriv dine tanker her...",
  EditComment: "Rediger kommentar",
  "UploadPhoto/File": "Last opp bilde/fil",
  ViewsDocument: "Viser dokument",
  Yourmessage: "Din beskjed",
  comingsoon: "Kommer snart",
  mycompany: "Mitt selskap",
  home_scaffold_welcome: "Velkommen til Salus Stillas",
  SendMessage: "Sende melding",
  productNumber: "Produktnummer",
  scaffoldLogs: "Stillasstokk",
  subscription: "ABONNEMENT",
  my_subscription: "MITT SELSKAP",
  uploadCalculations: "Last opp beregninger",
  today: "I dag",
  week: "Uke",
  month: "Måned",
  year: "År",
  workTaskTitle: "Arbeidsoppgavetittel",
  workTask: "Arbeidsoppgave",
  emergencyProcedure: "Nødprosedyrer",
  safetyChecklist: "Sikkerhetssjekkliste",
  requirementPermit: "Nødvendige tillatelser",
  riskLevel: "Risikonivå",
  protectiveEquipment: "Verneutstyr",
  addNewProtectiveEquipment: "Legg til nytt verneutstyr",
  addNewRequiredPermits: "Legg til nye nødvendige tillatelser",
  typeOfWork: "Type arbeid",
  addNewTypeOfWork: "Legg til ny type arbeid",
  workInHeights: "Arbeid i høyder",
  addNewDesignatedWorkArea: "Legg til nytt utpekt arbeidsområde",
  observationOverview: "Observasjonsoversikt",
  needsAction: "Trenger handling",
  underControl: "Under kontroll",
  completedObservation: "Fullført observasjon",
  observationDetail: "Nylig observasjonsdetalj",
  observationNeedAction: "Observasjon trenger handling",
  observationUnderControl: "Observasjon under kontroll",
  observationCompleted: "Observasjon fullført",
  observationCategory: "Observasjonskategori",
  safety: "sikkerhet",
  quality: "kvalitet",
  environmental: "Miljø",
  efficiency: "effektivitet",
  equipment: "utstyr",
  positive: "positivt",
  other: "annen",
  mediaAttachment: "Medievedlegg",
  photos_videos: "Bilder/videoer",

  termAndCondition: "Betingelse og vilkår",
  termAndConditionHeading: "Vilkår og betingelser for abonnementsplaner",
  introduction: "Introduksjon",
  introductionHeading: `Velkommen til Salus Stillas. Disse vilkårene og betingelsene "(vilkår)" styrer din bruk av våre tjenester og abonnementsplaner ("tjeneste") som tilbys gjennom vår plattform salusscaffold.com. Ved å abonnere på og bruke tjenesten vår godtar du å overholde og være bundet av disse vilkårene.`,
  subscriptionPlans: "Abonnementsplaner",
  planOptions: "Planalternativer",
  planOptionHeading:
    "Vi tilbyr ulike abonnementsplaner, inkludert men ikke begrenset til hver med spesifikke funksjoner og priser.",

  eligibility: "Kvalifisering",
  eligibilityHeading: "Du må være minst 18 år for å abonnere på tjenesten vår.",
  accountRegistration: "Kontoregistrering",
  accountRegistrationHeading:
    "For å abonnere må du opprette en konto på nettstedet vårt. Du er ansvarlig for å opprettholde konfidensialiteten til din kontoinformasjon og passord",
  billingAndPayment: "Fakturering og betaling",
  paymentMethod: "betalingsmetoder",
  paymentMethodHeading:
    "Vi aksepterer ulike betalingsmetoder, inkludert kredittkort, PayPal og andre betalingssystemer på nett.",
  billingCycle: "Faktureringssyklus",
  billingCycleHeading:
    "Abonnementer faktureres på månedlig eller årlig basis, avhengig av abonnementet du velger",
  automaticRenewal: "Automatisk fornyelse",
  automaticRenewalHeading:
    "Abonnementer fornyes automatisk ved slutten av hver faktureringssyklus med mindre du kansellerer abonnementet før fornyelsesdatoen",
  priceChanges: "Prisendringer",
  priceChangesHeading:
    "Vi forbeholder oss retten til å endre abonnementsavgifter når som helst. Vi vil varsle deg om eventuelle prisendringer på forhånd. Fortsatt bruk av tjenesten etter at prisendringen trer i kraft utgjør din samtykke til de nye avgiftene",
  refund: "Refusjoner",
  refundHeading:
    "Abonnementsavgifter kan ikke refunderes. Imidlertid kan vi gi refusjoner eller kreditter etter eget skjønn under visse omstendigheter",
  cancellationAndTermination: "Kanselleringer og oppsigelse",
  cancellationByYou: "Avbestilling av deg",
  cancellationByYouHeading:
    "Du kan kansellere abonnementet ditt når som helst gjennom kontoinnstillingene på nettstedet vårt. Kanselleringer trer i kraft ved slutten av gjeldende faktureringssyklus",
  terminationByUs: "Oppsigelse fra oss",
  terminationByUsHeading:
    "Vi kan suspendere eller avslutte kontoen din og tilgang til tjenesten hvis du bryter disse vilkårene eller deltar i uredelige, fornærmende eller ulovlige aktiviteter",
  userConduct: "Brukeratferd",
  prohibitedActivity: "Forbudte aktiviteter",
  prohibitedActivityHeading:
    "Du samtykker i å ikke bruke tjenesten til ulovlige formål eller på en måte som bryter disse vilkårene",
  accountSecurity: "Kontosikkerhet",
  accountSecurityHeading:
    "Du er ansvarlig for alle aktiviteter som skjer under kontoen din. Du må varsle oss umiddelbart om enhver uautorisert bruk av kontoen din",
  accountSharing: "Kontodeling",
  accountSharingHeading:
    "Kontoen din er din alene, du skal ikke dele eller godta andres logginformasjon",
  intellectProperty: "Åndsverk",
  ownerships: "Eie",
  ownershipsHeading:
    "Vi eier alle rettigheter, tittel og interesser i og til tjenesten, inkludert alle immaterielle rettigheter",
  license: "Tillatelse",
  licenseHeading:
    "Vi gir deg en begrenset, ikke-eksklusiv, ikke-overførbar lisens til å bruke tjenesten for dine personlige eller interne forretningsformål",
  privacy: "Personvern",
  privacyHeading:
    "Personvernet ditt er viktig for oss. Når du bruker våre tjenester, stoler du på oss med informasjonen din. Vi forstår at dette er et stort ansvar og jobber hardt for å beskytte informasjonen din og gi deg kontroll",
  limitationOfLiability: "Ansvarsbegrensning",
  limitationOfLiabilityHeading:
    "I den fulle utstrekning loven tillater, skal Salus Scaffold ikke holdes ansvarlig for indirekte, tilfeldige, spesielle, følgeskader eller straffskader, eller tap av fortjeneste eller inntekter, enten de er pådratt direkte eller indirekte, eller tap av data, bruk, goodwill, eller andre immaterielle tap, som følge av",
  limitationOfLiabilityPoint_01:
    "din bruk eller manglende evne til å bruke tjenesten",
  limitationOfLiabilityPoint_02:
    "enhver uautorisert tilgang til eller bruk av våre servere og/eller personlig informasjon som er lagret der",
  limitationOfLiabilityPoint_03:
    "ethvert avbrudd eller opphør av overføring til eller fra tjenesten",
  limitationOfLiabilityPoint_04:
    "eventuelle feil, virus, trojanske hester eller lignende som kan overføres til eller gjennom tjenesten vår av en tredjepart",
  limitationOfLiabilityPoint_05:
    "eventuelle feil eller utelatelser i innhold eller for tap eller skade som oppstår som følge av bruk av innhold som er lagt ut, sendt på e-post, overført eller på annen måte gjort tilgjengelig gjennom tjenesten; og/eller",
  limitationOfLiabilityPoint_06:
    "ærekrenkende, støtende eller ulovlig oppførsel fra en tredjepart. Salus Scaffolds samlede ansvar for alle krav knyttet til tjenesten skal ikke under noen omstendigheter overstige beløpet du har betalt til Salus Scaffold for tjenesten i løpet av de tolv (12) månedene før datoen for hendelsen som ga opphav til ansvaret",
  indemnification: "Skadeserstatning",
  indemnificationHeading:
    "Du samtykker i å skadesløs, forsvare og holde Salus Scaffold, dets tilknyttede selskaper og deres respektive offiserer, direktører, ansatte, agenter og representanter skadesløse fra og mot alle krav, ansvar, skader, tap, kostnader og utgifter, inkludert rimelige advokaters gebyrer som oppstår som følge av eller på noen måte knyttet til din bruk av tjenesten eller brudd på disse vilkårene",
  changeOfTheTerm: "Endringer i vilkårene",
  changeOfTheTermHeading:
    "Vi kan oppdatere disse vilkårene fra tid til annen. Vi vil varsle deg om eventuelle endringer ved å legge ut de nye vilkårene på nettstedet vårt. Du anbefales å gjennomgå disse vilkårene med jevne mellomrom for eventuelle endringer. Din fortsatte bruk av tjenesten etter at endringene trer i kraft, innebærer at du godtar de nye vilkårene",
  contactUsDetail:
    "Hvis du har spørsmål om disse vilkårene, vennligst kontakt oss på post@salsusscaffold.com",
  acceptTermsAndConditions: "Godta vilkår og betingelser",
  describeAction: "Beskriv handling?",
  describeFurtherAction: "Er det behov for ytterligere tiltak?",
  riskAssessment: "Risikovurdering",
  footer_bottom_text: "2024 Salus Scaffold, Alle rettigheter forbeholdt",
  change_password: "Bytt passord",
  after_control_form_list: "Etter kontrollskjemaliste",
  control_name: "Kontrollnavn",
  created_at: "Opprettet kl",
  updated_at: "Oppdatert kl",
  previous: "Tidligere",
  faq_6_ans: "Ring +47 902 324 08 Send en e-post post@salusscaffold.com",

  start_date: "Startdato",
  end_date: "Sluttdato",

  select_date: "velg dato",
  filter_option: "Filteralternativ",

  all: "Alle",
  active: "Aktiv",
  dismantle: "Demontert",

  scaffold_log_search:
    "Søk på spesifikk plassering, stillasnummer, stillasnavn, dato",

  units: "Enheter",
  total: "Total",

  onlyNumber: "Bare skriv inn nummer",
  addSubtask: "Legg til underoppgave",
  extendedList: "Utvidet liste",

  subscription_description_01: "Velg en plan som passer for deg",
  subscription_description_02:
    "Enkel, gjennomsiktig prissetting som vokser med deg.",
  subscription_popular: "Populær",
  subscription_monthly: "Månedlig",
  subscription_yearly: "Årlig",
  subscription_get_started: "Kom i gang",
  subscription_confirmation: "Er du sikker på at du vil kjøpe abonnementet?",
  subscription_cancel: "Avbryt",
  subscription_loading: "Laster",
  subscription_confirm: "Bekreft",
  subscription_info_01: "Du har allerede abonnementsplan",
  subscription_go_back: "Gå tilbake",

  subscription_confirmation_01: "Abonnementsbekreftelse",
  subscription_confirmation_detail: "Abonnementsplan: 25+ brukere",
  subscription_confirmation_description: "Beskrivelse",
  subscription_confirmation_note:
    "Takk for at du valgte vårt abonnement på 25+ brukere!",
  subscription_confirmation_body_01:
    "Vi forstår de unike behovene til store team og er forpliktet til å gi deg den beste servicen og sikkerheten på bedriftsnivå. Vårt team vil kontakte deg snart for å diskutere dine krav i detalj og tilby en skreddersydd løsning som møter dine spesifikke behov.",
  subscription_confirmation_body_02:
    "Vennligst forvent en samtale fra oss snart. Vi er dedikerte til å sikre at du får best mulig verdi og service for investeringen din.",

  service_material: "Materialliste",
  service_my_subscription: "Mitt abonnement",
  service_subscription: "Abonnement",
  service_change_password: "Endre passord",
  service_subscription_header: "ABONNEMENT",
  service_subscription_title: "Velkommen til Salus Stillas!",
  my_subscription_header: "MITT ABONNEMENT",
  my_subscription_status: "Ikke abonnert",
  my_subscription_plan_text: "abonnementsplan",
  my_subscription_start: "Start abonnement",
  my_subscription_end: "Slutt på abonnement",
  my_subscription_remaining: "Gjenstående dager",
  my_subscription_days_left: "Dager igjen",
  my_subscription_active_user: "Aktive brukere",
  my_subscription_remaining_users: "Gjenværende brukere",
  my_subscription_users: "brukere",
  my_subscription_view_users: "Se brukere",
  my_subscription_add_users: "Legg til brukere",
  my_subscription_cancel: "Avbestille abonnementet",
  my_subscription_userName: "Brukernavn",
  my_subscription_userEmail: "Bruker e-post",
  my_subscription_userPhone: "Telefonnr.",
  my_subscription_userCompany: "Selskap",
  my_subscription_userPassword: "Passord",
  my_subscription_list: "Liste over abonnerte brukere",
  my_subscription_access_granted: "Tilgangsstatus",
  my_subscription_access_date: "Dato for tilgang",
  my_subscription_access_status: "Tilgangsstatus",
  my_subscription_access_edit: "Redigere",
  my_subscription_access_active: "Aktiv",
  my_subscription_access_inactive: "Inaktiv",
  my_subscription_data_status: "Ingen data tilgjengelig",

  user_guide: "Brukerhåndboken",

  sja_hard_hat: "Hard Hat",
  sja_safety_glassess: "Vernebriller",
  sja_gloves: "Hansker",
  sja_safety_boots: "Sikkerhetsstøvler",
  sja_high_visibility_cloths: "Klær med høy synlighet",
  sja_hearing_protection: "Hørselsvern",
  sja_dust_mask: "Støv/filtermaske",
  sja_protection_harness: "Beskyttelsessele",
  sja_work_height: "Arbeid i høyder",
  sja_confined_space: "Begrenset plass",
  sja_hot_work: "Varmt arbeid",
  sja_cordon: "Sperre av område",
  sja_question_01: "Er det nok belysning?",
  sja_question_02: "Har arbeidslaget den rette kompetansen for oppgaven?",
  sja_question_03:
    "Er alle i arbeidslaget enige om at jobben kan utføres trygt?",
  sja_question_04: "Er det annet arbeid i området?",
  custom_list: "Egendefinert liste",
  my_subscriber: "abonnenten min",
  invited_person: "Liste over inviterte personer",
  emil: "E-post",
  position: "Posisjon",
  quick_invite: "Rask invitasjon",
  remove_invite: "Fjern invitasjon",
  no_data_found: "Ingen data funnet",
  user_manual: "Brukermanual",
  per_month_per_user: "per måned per bruker",
  per_year_per_user: "per år per bruker",
  size_of_scaffold: "Størrelse på stillaset",
  name: "Navn",
  unit: "Enhet",
  calculation: "Beregning",
  total: "Totalt",
  size: "Størrelse",
  sq_meter: "kvadratmeter",
  cu_meter: "kubikkmeter",
  ln_meter: "Lengde meter",
  add_more: "Legg til mer",
  remove: "Fjerne",
  length: "Lengde",
  width: "Width",
  height: "Høyde",

  my_company: "Mitt firma",
  actions: "Handlinger",
  about_1_title: "Velkommen til Salus Scaffold!",
  about_1_desc:
    "Hos Salus Scaffold er vi dedikert til å revolusjonere stillasindustrien gjennom intuitiv teknologi og dyp bransjekunnskap. Målet vårt er å gi stillasbyggerne moderne verktøy som forenkler komplekse oppgaver og prioriterer sikkerhet.",
  about_2_title: "Vår historie",
  about_2_desc:
    "Født ut av behovet for en skreddersydd løsning på stillasutfordringer, ble Salus Scaffold utviklet av en gruppe bransjefolk som forstår bransjens intrikate behov. Vi så en mulighet til å gjøre stillasadministrasjonen mer effektiv, tryggere og mindre avhengig av utdaterte systemer. Teamet vårt samler eksperter innen konstruksjon, stillas og teknologi for å bygge en plattform som løser reelle problemer i bransjen.",
  about_3_title: "Hva tilbyr vi?",
  about_3_desc:
    "Salus Scaffold er en omfattende plattform designet for å administrere stillasprosjekter fra start til slutt. Enten det er stillasinspeksjoner, materialsporing, risikovurderinger eller arbeidsplassrapporter, samler vi alle disse kritiske oppgavene i ett brukervennlig system. Noen av våre nøkkelfunksjoner inkluderer    ",
    about_3_list_1:
    "Inspeksjon og godkjenning av stillas",
  about_3_list_1_1:"Gjør enkelt og dokumenter stillasinspeksjoner.",
  about_3_list_2:
    "Prosjektledelse",
  about_3_list_2_1:"Spor pågående prosjekter, tildel oppgaver og overvåk fremdriften i sanntid.",
  about_3_list_3:
    "Materialkontroll",
  about_3_list_3_1:"Lag egendefinerte materiallister og administrer beholdningen sømløst.",
  about_3_list_4:
    "Risikovurderinger",
  about_3_list_4_1:"Generer detaljerte sikkerhetsplaner som sikrer overholdelse og reduserer risikoer.",
  about_3_list_5:
    "Stillaslogger",
  about_3_list_5_1:"Få tilgang til historiske data og rapporter fra tidligere jobber for å informere fremtidige prosjekter.",
  about_4_title: "Hvorfor vi skiller oss ut",
  about_4_desc:
    "Vi vet at stillasfagfolk har vært underbetjent av generisk konstruksjonsprogramvare for lenge. Med Salus Scaffold har vi bygget en løsning spesifikt for ditt fag, som sikrer at hver funksjon er skreddersydd for de unike kravene til stillasarbeid. Ved å bygge bro mellom teknologi og handelsekspertise, tar vi sikte på å forbedre sikkerheten, effektivisere driften og øke produktiviteten på alle arbeidsplasser.",
  about_5_title: "Vår visjon",
  about_5_desc:
    "Vår visjon er enkel: å gjøre stillashåndtering smartere, tryggere og mer effektiv. Vi tror at fremtiden for stillas er digital, og vi er forpliktet til å tilby verktøy som møter og overgår industriens behov.",
  about_6_title: "Involver deg",
  about_6_desc:
    "Vi inviterer både stillasførere, prosjektledere og bedriftseiere til å bli med oss ​​for å skape en tryggere og mer organisert fremtid. Enten du er et lite team eller administrerer flere store prosjekter, er Salus Scaffold bygget for å vokse med deg.",
  access_model_title: "Velg rollen for denne e-posten",
  join_us_msg:"La oss bygge fremtiden for stillas sammen.",
  confirmBtn: "Bekreft",
  cancelBtn: "Avbryt",
  loadingTxt: "Laster...",
  buildAccording: "Bygg i henhold til brukerveiledning",
  buildDay: "Byggedagen",
  dismantleDay: "Demonteringsdagen",
};
