import React, { useState, useEffect } from "react";
import TopSection from "../components/forms/TopSection";
import { GoSearch } from "react-icons/go";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {
  createFilesService,
  deleteFilesService,
  filesService,
  searchFilesService,
} from "../Services/filesServices";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import FileCard from "../components/files/FileCard";
import no_data from "../Assets/noData.svg";
import { t } from "../utils/translate";
import { useParams } from "react-router-dom";
import { uploadImageService } from "../Services/filesServices";
import ImageUpload from "../components/FileUpload";

const FilesPage = () => {
  const currentLanguage = useSelector(
    (state) => state?.global?.current_language
  );
  const { accessLevel: roleOfUser } = useSelector((state) => state?.project);
  const [filesData, setFilesData] = useState([]);
  const [itemsToShow, setItemsToShow] = useState(4);
  const [Id, setId] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredProjects, setFilteredProjects] = useState([]);
  const files = useSelector((store) => store.file);
  const projectName = useSelector((store) => store?.project?.projectName);
  const userId = useSelector((state) => state?.auth?.loggedInUser?._id);
  const loadMoreData = () => {
    setItemsToShow(itemsToShow + 5);
  };
  const { projectId } = useParams();
  useEffect(() => {
    if (searchTerm.trim() !== "") {
      delayedAPICall(searchTerm);
    } else {
      fileData();
    }
    return () => clearTimeout(debounceTimer);
  }, [searchTerm]);

  const fileData = async () => {
    try {
      setLoading(true);
      const response = await filesService(projectId);
      if (response && response.files) {
        const filterData = response.files.filter(
          (file) => !file.isDeleted && file?.fileType === "file"
        );
        setFilesData(
          filterData.map((element) => ({
            ...element,
            isInputOpen: false,
            isSelected: false,
          }))
        );
      }
    } catch (error) {
      console.error("Error fetching files:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    setId(id);
    const response = await deleteFilesService(id);
    if (response?.status === "success") {
      toast.success(t("deletedSuccessfully"));
      fileData();
    } else {
      toast.error(t("thereIsSomeError"));
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fileData();
  }, []);

  let debounceTimer;

  const delayedAPICall = (term) => {
    setLoading(true);
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(async () => {
      try {
        const response = await searchFilesService(term);

        setFilesData(response?.files);
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }, 1000);
  };

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
  };

  const handleImageUpload = async (file) => {
    console.log(file, "file passed");
    // Directly use the file since it's passed directly
    if (file) {
      const data = { userId, fileType: "file", file, projectId };
      console.log(file, "file me hu");
      console.log(data, "data me hu");
      try {
        setLoading(true);
        const response = await createFilesService(data);
        console.log(response, "helloji");
        if (response?.data && response?.data.file) {
          toast.success(t("fileUploadedSuccessfully"));
          fileData();
        } else {
          toast.error(t("thereIsSomeError"));
        }
      } catch (error) {
        toast.error(t("uploadFailed"));
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <Header />
      <TopSection
        keys={projectId}
        title={t("files")}
        breadcrumData={[t("home"), projectName.toUpperCase(), t("files")]}
      />
      <div className="pb-5 mb-4 border-b border-b-[#CCCCCC]">
        <div className="custom-container">
          <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
            {/* Files Title */}
            <p className="title-text">{t("files")}</p>

            {/* Search Input */}
            <div className="relative sm:w-[600px] w-full">
              <GoSearch
                className="absolute top-[50%] left-[10px] translate-y-[-50%]"
                size={24}
                color="#000000"
              />
              <input
                onChange={handleSearch}
                className="border border-[#CCCCCC] pl-[40px] py-[10px] w-full rounded"
                placeholder={t("searchFiles")}
                type="text"
              />
            </div>

            {/* Upload Button */}
            <div className="flex justify-end">
              <label
                htmlFor="imageUpload"
                className="bg-[#0072bb] text-white flex justify-center items-center w-[150px] rounded-[5px] px-[20px] py-[10px] button-text cursor-pointer"
              >
                <ImageUpload
                  onImageUpload1={(file) => handleImageUpload(file)}
                />
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="custom-container">
        {searchTerm ? (
          <>
            {loading ? (
              <>
                <div className="text-center mt-10 ">
                  <div
                    className="flex flex-col justify-center items-center  gap-[10px]"
                    role="status"
                  >
                    <svg
                      aria-hidden="true"
                      className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <h1 className="text-[20px] font-[700] text-[#0072BB]">
                      {t("loading")}
                    </h1>
                  </div>
                </div>
              </>
            ) : (
              <>
                {filesData
                  ?.filter((item) => item.fileType === "file")
                  .slice(0, itemsToShow)?.length > 0 ? (
                  <>
                    <div className="grid grid-cols-1 ipad-mini-grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10">
                      {filesData
                        ?.filter((item) => item.fileType === "file")
                        .slice(0, itemsToShow)
                        .map((item, index) => (
                          <FileCard
                            {...item}
                            handleDelete={handleDelete}
                            key={index}
                            roleOfUser={roleOfUser}
                          />
                        ))}
                    </div>
                    <div className="pt-[30px] flex justify-center">
                      <button
                        onClick={loadMoreData}
                        className="border bg-[#0072bb] text-[#fff] flex justify-center items-center  w-[150px] button-text rounded-[5px] px-[20px] py-[10px] button-text"
                      >
                        {t("viewMore")}
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="flex justify-center items-center mt-[10px]">
                    <img
                      className="w-[70%]"
                      src={no_data}
                      alt="no data found"
                    />
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <>
            {loading ? (
              <>
                <div className="text-center mt-10 ">
                  <div
                    className="flex flex-col justify-center items-center  gap-[10px]"
                    role="status"
                  >
                    <svg
                      aria-hidden="true"
                      className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <h1 className="text-[20px] font-[700] text-[#0072BB]">
                      {t("loading")}
                    </h1>
                  </div>
                </div>
              </>
            ) : (
              <>
                {filesData
                  ?.filter((item) => item.fileType === "file")
                  .slice(0, itemsToShow)?.length > 0 ? (
                  <>
                    <div className="grid grid-cols-1 ipad-mini-grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10">
                      {filesData
                        ?.filter((item) => item.fileType === "file")
                        .slice(0, itemsToShow)
                        ?.map((item, index) => (
                          <FileCard {...item} handleDelete={handleDelete} />
                        ))}
                    </div>
                    {itemsToShow < filesData?.length && (
                      <div className="pt-[30px] flex justify-center">
                        <button
                          onClick={loadMoreData}
                          className="border bg-[#0072bb] text-[#fff] flex justify-center items-center  w-[150px] button-text rounded-[5px] px-[20px] py-[10px] button-text"
                        >
                          {t("viewMore")}
                        </button>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="flex justify-center items-center mt-[10px]">
                    <img
                      className="w-[70%]"
                      src={no_data}
                      alt="no data found"
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default FilesPage;
