import React from "react";
import { useSelector } from "react-redux";
import { t } from "../utils/translate";
import { Link } from "react-router-dom";

const Footer = () => {
  const currentLanguage = useSelector(
    (state) => state?.global?.current_language
  );

  return (
    <>
      <div className="flex justify-end items-end sm:h-[30vh]">
        <img
          className="min-h-[150px] w-full object-cover"
          src="/form-bottom.svg"
          alt="Footer decoration"
        />
      </div>
      <div className="relative bg-[#0072BB]">
        <div className="custom-container">
          {/* Footer Grid: 4 sections */}
          <div className="py-[40px] border-b border-white grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
            {/* Section 1: Logo */}
            <div className="text-center lg:text-left">
              <img
                src="/new_logo.svg"
                className="mx-auto lg:mx-0 w-[180px] lg:w-[280px] p-5 rounded-lg"
                width={280}
                alt="Company Logo"
              />
            </div>

            {/* Section 2: About Us */}
            <div>
              <p className="text-white medium-title">{t("aboutUs")}</p>
              <p className="text-white mt-[10px] footer-text">
                {t("aboutUs-description")}
              </p>
            </div>

            {/* Section 3: Contact */}
            <div>
              <p className="medium-title text-white mb-[20px]">{t("contact")}</p>
              <div className="flex flex-col gap-[10px]">
                <div className="flex items-center gap-[20px] justify-start">
                  <img src="/call-white.svg" alt="Phone icon" />
                  <p className="footer-text text-white">+47 902 324 08</p>
                </div>
                <div className="flex items-center gap-[20px] justify-start">
                  <img src="/email-white.svg" alt="Email icon" />
                  <p className="footer-text text-white">
                    Support@salusscaffold.com
                  </p>
                </div>
              </div>
            </div>

            {/* Section 4: Follow Us */}
            <div>
              <p className="medium-title text-white mb-[20px]">{t("follow-us")}</p>
              <div className="flex items-center gap-[20px] justify-start">
                <a
                  href="https://www.facebook.com/people/Salus-Scaffold/61560883563967/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/facebook.svg" alt="Facebook" />
                </a>
                <a
                  href="https://www.linkedin.com/company/103630645/admin/feed/posts/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/linkedin.svg" alt="LinkedIn" />
                </a>
                <img src="/instagram.svg" alt="Instagram" />
              </div>
            </div>
          </div>

          {/* Footer Bottom Text */}
          <div className="text-center py-[10px]">
            <p className="copyright-text text-white">
              &copy; {t("footer_bottom_text")}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;