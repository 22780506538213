import React, { useEffect, useMemo, useState } from "react";
import { getApprovalFormByProjectId } from "../../Services/approvalFormService";
import ScaffoldPricing from "./ScaffoldPricing";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Card,
  Container,
} from "@mui/material";
import { useParams } from "react-router-dom";
import DateRangePicker from "../DateRangePicker";

export default function VolumePage() {
  const { projectId } = useParams();
  const isAdminId = true;
  const scaffoldOptions = {
    "Standard Scaffold": {
      pricePerM3: 10,
      pricePerM2: 8,
      pricePerLM: 3,
      pricePerHM: 2,
      hourlyRate: 20,
    },
    "Fasade Scaffold": {
      pricePerM3: 9,
      pricePerM2: 15,
      pricePerLM: 3,
      pricePerHM: 2,
      hourlyRate: 20,
    },
    "Hanging Scaffold": {
      pricePerM3: 15,
      pricePerM2: 12,
      pricePerLM: 8,
      pricePerHM: 8,
      hourlyRate: 20,
    },
    "Rolling Scaffold": {
      pricePerM3: 18,
      pricePerM2: 20,
      pricePerLM: 4,
      pricePerHM: 4,
      hourlyRate: 20,
    },
    "Support Scaffold": {
      pricePerM3: 40,
      pricePerM2: 40,
      pricePerLM: 10,
      pricePerHM: 10,
      hourlyRate: 20,
    },
  };
  const [isSaved, setIsSaved] = useState(false);
  const [scaffoldSize, setScaffoldSize] = useState([]);
  const [selectedScaffoldType, setSelectedScaffoldType] = useState("");
  const [selectedPriceDetailsUpdate, setSelectedPriceDetailsUpdate] =
    useState(scaffoldOptions);
  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFilteredData] = useState();
  const [selectedScaffoldBase, setSelectedScaffoldBase] =
    useState("Volume Based");
  const [percentages, setPercentages] = useState([]);

  const getPriceForKey = (key) => {
    const scaffold = scaffoldOptions[selectedScaffoldType];
    if (scaffold) {
      return key === "m2"
        ? scaffold.pricePerM2
        : key === "m3"
        ? scaffold.pricePerM3
        : key === "lm"
        ? scaffold.pricePerLM
        : key === "hm"
        ? scaffold.pricePerHM
        : key === "hr"
        ? scaffold.hourlyRate
        : 0;
    }

    return 0;
  };
  const getApprovalFormByItsProjectId = async () => {
    try {
      const response = await getApprovalFormByProjectId(projectId);
      setScaffoldSize(response?.data?.data || []);
    } catch (error) {
      return error;
    }
  };

  const activeScaffolds = scaffoldSize?.filter(
    (item) => item.status === "active"
  );

  const activeScaffoldsDetails = activeScaffolds?.map((item) =>
    item.scaffoldName?.map((scaffold) => ({
      ...scaffold,
      scaffoldIdentificationNumber: item.scaffoldIdentificationNumber,
    }))
  );

  const handleScaffoldSelectBase = (e) => {
    setSelectedScaffoldBase(e.target.value);
  };

  const handlePriceChange = (field, value) => {
    setSelectedPriceDetailsUpdate((prevPrices) => ({
      ...prevPrices,
      [selectedScaffoldType]: {
        ...prevPrices[selectedScaffoldType],
        [field]: parseFloat(value) || 0,
      },
    }));
    setIsSaved(false);
  };

  const handleSavePrices = () => {
    setIsSaved(true);
  };

  const handleScaffoldSelectType = (event) => {
    const selectedType = event.target.value;
    setSelectedScaffoldType(selectedType);
  };

  const handlePercentageChange = (index, type, value) => {
    const updatedValue = Math.max(0, Math.min(100, parseFloat(value) || 0));
    setPercentages((prevPercentages) => {
      const newPercentages = [...prevPercentages];
      if (type === "build") {
        newPercentages[index] = {
          build: updatedValue,
          dismantle: 100 - updatedValue,
        };
      } else if (type === "dismantle") {
        newPercentages[index] = {
          build: 100 - updatedValue,
          dismantle: updatedValue,
        };
      }
      return newPercentages;
    });
  };

  const totalHours = filteredData?.reduce(
    (acc, { TotalHourJob }) => acc + TotalHourJob,
    0
  );
  const totalAmount = filteredData?.reduce(
    (acc, { TotalAmount }) => acc + TotalAmount,
    0
  );

  const scaffoldTotalsHours = useMemo(() => {
    return activeScaffoldsDetails?.map((scaffoldGroup) => {
      const scaffold = scaffoldGroup[0];

      const m3Total =
        getPriceForKey("m3") *
        scaffold.measurements.m3?.reduce(
          (acc, m3) =>
            acc +
            parseFloat(m3?.length) *
              parseFloat(m3?.width) *
              parseFloat(m3?.height),
          0
        );

      const m2Total =
        getPriceForKey("m2") *
        scaffold.measurements.m2?.reduce(
          (acc, m2) => acc + parseFloat(m2?.length) * parseFloat(m2?.width),
          0
        );

      const lmTotal =
        getPriceForKey("lm") *
        scaffold.measurements.lm?.reduce(
          (acc, lm) => acc + parseFloat(lm?.length),
          0
        );

      const hmTotal =
        getPriceForKey("hm") *
        scaffold.measurements.hm?.reduce(
          (acc, hm) => acc + parseFloat(hm?.height),
          0
        );

      const TotalAmount = m3Total + m2Total + lmTotal + hmTotal;
      const TotalHourJob = Math.round(TotalAmount / getPriceForKey("hr"));

      return { scaffold, TotalHourJob, TotalAmount };
    });
  }, [activeScaffoldsDetails]);

  useEffect(() => {
    const filteredByType = selectedScaffoldType
      ? scaffoldTotalsHours?.filter(
          (item) =>
            item.scaffold.key.toLowerCase() ===
            selectedScaffoldType.toLowerCase()
        )
      : scaffoldTotalsHours;

    const filtered =
      searchInput?.length >= 3
        ? filteredByType?.filter(
            ({ scaffold }) =>
              scaffold.value
                .toLowerCase()
                .includes(searchInput.toLowerCase()) ||
              scaffold.scaffoldIdentificationNumber.includes(searchInput)
          )
        : filteredByType;

    setFilteredData(filtered);
  }, [searchInput, scaffoldTotalsHours, selectedScaffoldType]);

  useEffect(() => {
    if (percentages?.length === 0 && scaffoldTotalsHours?.length > 0) {
      const initialPercentages = scaffoldTotalsHours?.map(() => ({
        build: 50,
        dismantle: 50,
      }));
      setPercentages(initialPercentages);
    }
  }, [scaffoldTotalsHours, percentages]);

  useEffect(() => {
    getApprovalFormByItsProjectId();
    console.log(projectId, "ProjectId");
  }, [projectId]);

  return (
    <Container>
      <h1 className="title-text text-center mb-20">VOLUME BASED PRICE</h1>
      {/* <DateRangePicker
        onDateRangeChange={handleDateRangeChange}
        dateRange={dateRange}
      /> */}
      <ScaffoldPricing
        selectedScaffoldType={selectedScaffoldType}
        priceDetails={selectedPriceDetailsUpdate[selectedScaffoldType]}
        isAdminId={isAdminId}
        handlePriceChange={handlePriceChange}
      />

      {isAdminId && (
        <div className="flex justify-center">
          <button
            type="button"
            onClick={handleSavePrices}
            className="mt-[20px] px-[20px] py-[10px] bg-[#0072BB] text-white border-none rounded-[5px] flex justify-center"
          >
            Save Prices
          </button>
        </div>
      )}

      {isSaved && (
        <p className="save-confirmation">Prices saved successfully!</p>
      )}
      <div className="flex justify-around items-center space-x-4 mt-10">
        <div className="relative w-1/3">
          <input
            type="text"
            placeholder="Search..."
            className="!h-[40px] !w-full !rounded-[6px] !border-[1px] border-[rgba(204,204,204,1)] shadow-sm focus:outline-none !focus:ring-2 !focus:ring-blue-500 !focus:border-blue-500 !appearance-none"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
          <span className="absolute inset-y-0 left-3 flex items-center text-gray-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 21l-4.35-4.35M15.6 15.6a7.5 7.5 0 111.06-1.06 7.5 7.5 0 01-1.06 1.06z"
              />
            </svg>
          </span>
        </div>
        <div className="relative w-1/3">
          <select
            className="w-full px-4 py-2 border border-[rgba(204,204,204,1)] rounded-lg shadow-sm focus:outline-none !focus:ring-2 focus:ring-blue-500 focus:border-blue-500 !appearance-none"
            onChange={handleScaffoldSelectBase}
            value={selectedScaffoldBase}
          >
            <option value="Volume Based">Volume Based</option>
            <option value="Hour Based">Hour Based</option>
          </select>
        </div>
        <div className="relative w-1/3">
          <select
            className="w-full px-4 py-2 border border-[rgba(204,204,204,1)] rounded-lg shadow-sm focus:outline-none !focus:ring-2 focus:ring-blue-500 focus:border-blue-500 !appearance-none"
            onChange={handleScaffoldSelectType}
            value={selectedScaffoldType}
          >
            <option value="">Select Scaffold Type</option>
            {Object.keys(scaffoldOptions)?.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div>
        <Table aria-label="scaffolding table" sx={{ whiteSpace: "nowrap" }}>
          <TableHead>
            <TableRow>
              {/* Table Header */}
              <TableCell>
                <Typography variant="h5">Active</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h5">ID</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h5">M3</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h5">M2</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h5">LM</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h5">HM</Typography>
              </TableCell>
              {selectedScaffoldBase === "Hour Based" ? (
                <>
                  <TableCell>
                    <Typography variant="h5">Total Hr</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h5">Build Hr</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h5">Dismantle Hr</Typography>
                  </TableCell>
                </>
              ) : (
                <>
                  <TableCell>
                    <Typography variant="h5">Total $</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h5">Build $</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h5">Dismantle $</Typography>
                  </TableCell>
                </>
              )}
              <TableCell>
                <Typography variant="h5">Build %</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h5">Dismantle %</Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {filteredData?.map(
              ({ scaffold, TotalHourJob, TotalAmount }, index) => {
                const buildAmount =
                  ((percentages[index]?.build || 0) / 100) * TotalAmount;
                const dismantleAmount =
                  ((percentages[index]?.dismantle || 0) / 100) * TotalAmount;

                const buildHour =
                  ((percentages[index]?.build || 0) / 100) * TotalHourJob;
                const dismantleHour =
                  ((percentages[index]?.dismantle || 0) / 100) * TotalHourJob;
                console.log(buildAmount, "buildAmount");
                console.log(dismantleAmount, "dismantleAmount");

                return (
                  <TableRow key={index}>
                    <TableCell>{scaffold.value}</TableCell>
                    <TableCell>
                      {scaffold.scaffoldIdentificationNumber}
                    </TableCell>
                    <TableCell>
                      {scaffold.measurements.m3?.reduce(
                        (acc, m3) =>
                          acc +
                          parseFloat(m3?.length) *
                            parseFloat(m3?.width) *
                            parseFloat(m3?.height),
                        0
                      )}
                    </TableCell>
                    <TableCell>
                      {scaffold.measurements.m2?.reduce(
                        (acc, m2) =>
                          acc + parseFloat(m2?.length) * parseFloat(m2?.width),
                        0
                      )}
                    </TableCell>
                    <TableCell>
                      {scaffold.measurements.lm?.reduce(
                        (acc, lm) => acc + parseFloat(lm?.length),
                        0
                      )}
                    </TableCell>
                    <TableCell>
                      {scaffold.measurements.hm?.reduce(
                        (acc, hm) => acc + parseFloat(hm?.height),
                        0
                      )}
                    </TableCell>
                    {selectedScaffoldBase === "Hour Based" ? (
                      <>
                        <TableCell>{TotalHourJob ? TotalHourJob : 0}</TableCell>
                        <TableCell>
                          {buildHour ? buildHour.toFixed(2) : 0}
                        </TableCell>
                        <TableCell>
                          {dismantleHour ? dismantleHour.toFixed(2) : 0}
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell>{TotalAmount ? TotalAmount : 0}</TableCell>
                        <TableCell>
                          {buildAmount ? buildAmount.toFixed(2) : 0}
                        </TableCell>
                        <TableCell>
                          {dismantleAmount ? dismantleAmount.toFixed(2) : 0}
                        </TableCell>
                      </>
                    )}
                    <TableCell>
                      <TextField
                        type="number"
                        value={percentages[index]?.build || 0}
                        onChange={(e) =>
                          handlePercentageChange(index, "build", e.target.value)
                        }
                        inputProps={{
                          min: 0,
                          max: 100,
                        }}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        value={percentages[index]?.dismantle || 0}
                        onChange={(e) =>
                          handlePercentageChange(
                            index,
                            "dismantle",
                            e.target.value
                          )
                        }
                        inputProps={{
                          min: 0,
                          max: 100,
                        }}
                        size="small"
                      />
                    </TableCell>
                  </TableRow>
                );
              }
            )}
            {selectedScaffoldBase === "Hour Based" ? (
              <TableRow>
                <TableCell colSpan={4}></TableCell>{" "}
                <TableCell colSpan={2}>
                  <Typography variant="h6">Total Hours</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6">
                    {totalHours ? totalHours : 0}
                  </Typography>
                </TableCell>
                <TableCell colSpan={4}></TableCell>{" "}
              </TableRow>
            ) : (
              <TableRow>
                <TableCell colSpan={4}></TableCell>{" "}
                <TableCell colSpan={2}>
                  <Typography variant="h6">Total Amount</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6">
                    {totalAmount ? totalAmount : 0}
                  </Typography>
                </TableCell>
                <TableCell colSpan={4}></TableCell>{" "}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </Container>
  );
}
