import React, { useEffect, useState } from "react";

const WelcomeModal = (props) => {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    const hasSeenModal = localStorage.getItem("hasSeenModal");

    if (!hasSeenModal) {
      setIsOpen(true);
      localStorage.setItem("hasSeenModal", "true");
    }
  }, []);

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-75">
          <div className="bg-white rounded-lg shadow-lg max-w-lg w-full sm:p-10 p-3 relative mx-3">
            <button
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 focus:outline-none"
              onClick={closeModal}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <img
              src="/logo-welcome-popup.jpg"
              alt="Welcome"
              className="mx-auto h-48 object-cover"
            />
            <h2 className="text-2xl font-semibold text-gray-800 mt-4">
              Hey {props?.userData?.name} <spam>👋</spam>
            </h2>{" "}
            <h2 className="text-2xl font-semibold text-gray-800 mt-4">
              Welcome to Salus Scaffold!
            </h2>
            <p className="text-gray-600 mt-2">
              We're excited to have you here. Explore our site and let us know
              if you have any questions.
            </p>
            <button
              onClick={closeModal}
              className="mt-6 w-full bg-[#0072bb] text-white py-2 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50"
            >
              Get Started
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default WelcomeModal;
