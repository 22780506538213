import React, { useState } from "react";
import Header from "../components/Header";
import TopSection from "../components/forms/TopSection";
import { t } from "../utils/translate";
import Footer from "../components/Footer";
import { useSelector } from "react-redux";

const TermAndConditionForSubscription = (props) => {
  const currentLanguage = useSelector(
    (state) => state?.global?.current_language
  );

  const [isChecked, setIsChecked] = useState(false);
  const handleProceedPayment = async () => {
    props.setIsLoading(true);
    const sendDataToPayment = {
      ...props?.currentData,
      priceType: props?.currentCheckBox[props?.currentIndex] ? "month" : "year",
    };
    const responsePayment = await props?.makePayment(sendDataToPayment);
    props?.setIsLoading(false);
  };

  return (
    <div>
      {!props.header && <Header />}
      <div>
        {!props?.topSection && (
          <TopSection
            keys={"unique"}
            title={"Welcome to Salus Scaffold!"}
            breadcrumData={[t("home"), t("termAndCondition")]}
          />
        )}
        <div className="custom-container shadow-sm">
          <p className="lg:text-3xl text-md font-bold text-[#0081c8] text-center">
            {t("termAndConditionHeading")}
          </p>

          <div className="shadow-sm mt-5 text-center">
            <p className="lg:text-2xl text-md font-bold text-[#0081c8] underline">
              {t("introduction")}
            </p>
            <p className="ps-5 mt-2 space-y-1 list-disc list-inside font-semibold">
              {" "}
              {t("introductionHeading")}
            </p>
          </div>

          <div className="shadow-sm mt-5 text-center">
            <p className="lg:text-2xl text-md font-bold text-[#0081c8] underline">
              {t("subscriptionPlans")}
            </p>

            <ol class="space-y-4  list-decimal list-inside text-start">
              <li className="font-bold">
                {t("planOptions")}
                <ul class="ps-5 mt-2 space-y-1 list-disc list-inside font-semibold">
                  <li>{t("planOptionHeading")}</li>
                </ul>
              </li>
              <li className="font-bold">
                {t("eligibility")}:
                <ul class="ps-5 mt-2 space-y-1 list-disc list-inside font-semibold">
                  <li>{t("eligibilityHeading")}</li>
                </ul>
              </li>
              <li className="font-bold">
                {t("accountRegistration")}:
                <ul class="ps-5 mt-2 space-y-1 list-disc list-inside font-semibold">
                  <li>{t("accountRegistrationHeading")}.</li>
                </ul>
              </li>
            </ol>
          </div>

          <div className="shadow-sm mt-5 text-center">
            <p className="lg:text-2xl text-md font-bold text-[#0081c8] underline">
              {t("billingAndPayment")}
            </p>

            <ol class="space-y-4  list-decimal list-inside text-start">
              <li className="font-bold">
                {t("paymentMethod")}:
                <ul class="ps-5 mt-2 space-y-1 list-disc list-inside font-semibold">
                  <li>{t("paymentMethodHeading")}</li>
                </ul>
              </li>
              <li className="font-bold">
                {t("billingCycle")}:
                <ul class="ps-5 mt-2 space-y-1 list-disc list-inside font-semibold">
                  <li>{t("billingCycleHeading")}.</li>
                </ul>
              </li>
              <li className="font-bold">
                {t("automaticRenewal")}:
                <ul class="ps-5 mt-2 space-y-1 list-disc list-inside font-semibold">
                  <li>{t("automaticRenewalHeading")}.</li>
                </ul>
              </li>{" "}
              <li className="font-bold">
                {t("priceChanges")}:
                <ul class="ps-5 mt-2 space-y-1 list-disc list-inside font-semibold">
                  <li>{t("priceChangesHeading")}.</li>
                </ul>
              </li>{" "}
              <li className="font-bold">
                {t("refund")}:
                <ul class="ps-5 mt-2 space-y-1 list-disc list-inside font-semibold">
                  <li>{t("refundHeading")}.</li>
                </ul>
              </li>
            </ol>
          </div>

          <div className="shadow-sm mt-5 text-center">
            <p className="lg:text-2xl text-md font-bold text-[#0081c8] underline">
              {t("cancellationAndTermination")}
            </p>

            <ol class="space-y-4  list-decimal list-inside text-start">
              <li className="font-bold">
                {t("cancellationByYou")}:
                <ul class="ps-5 mt-2 space-y-1 list-disc list-inside font-semibold">
                  <li>{t("cancellationByYouHeading")}.</li>
                </ul>
              </li>
              <li className="font-bold">
                {t("terminationByUs")}:
                <ul class="ps-5 mt-2 space-y-1 list-disc list-inside font-semibold">
                  <li>{t("terminationByUsHeading")}.</li>
                </ul>
              </li>
            </ol>
          </div>

          <div className="shadow-sm mt-5 text-center">
            <p className="lg:text-2xl text-md font-bold text-[#0081c8] underline">
              {t("userConduct")}
            </p>

            <ol class="space-y-4  list-decimal list-inside text-start">
              <li className="font-bold">
                {t("prohibitedActivity")}:
                <ul class="ps-5 mt-2 space-y-1 list-disc list-inside font-semibold">
                  <li>{t("prohibitedActivityHeading")}.</li>
                </ul>
              </li>
              <li className="font-bold">
                {t("accountSecurity")}:
                <ul class="ps-5 mt-2 space-y-1 list-disc list-inside font-semibold">
                  <li>{t("accountSecurityHeading")}.</li>
                </ul>
              </li>
              <li className="font-bold">
                {t("accountSharing")}:
                <ul class="ps-5 mt-2 space-y-1 list-disc list-inside font-semibold">
                  <li>{t("accountSharingHeading")}. </li>
                </ul>
              </li>
            </ol>
          </div>

          <div className="shadow-sm mt-5 text-center">
            <p className="lg:text-2xl text-md font-bold text-[#0081c8] underline">
              {t("intellectProperty")}
            </p>

            <ol class="space-y-4  list-decimal list-inside text-start">
              <li className="font-bold">
                {t("ownerships")}:
                <ul class="ps-5 mt-2 space-y-1 list-disc list-inside font-semibold">
                  <li>{t("ownershipsHeading")}.</li>
                </ul>
              </li>
              <li className="font-bold">
                {t("license")}:
                <ul class="ps-5 mt-2 space-y-1 list-disc list-inside font-semibold">
                  <li>{t("licenseHeading")}.</li>
                </ul>
              </li>
            </ol>
          </div>

          <div className="shadow-sm mt-5 text-center">
            <p className="lg:text-2xl text-md font-bold text-[#0081c8] underline">
              {t("privacy")}
            </p>
            <ul class="ps-5 mt-2 space-y-1 list-disc list-inside font-semibold text-start">
              <li>{t("privacyHeading")}.</li>
            </ul>
          </div>

          <div className="shadow-sm mt-5 text-center">
            <p className="lg:text-2xl text-md font-bold text-[#0081c8] underline">
              {t("limitationOfLiability")}
            </p>
            <ul class="ps-5 mt-2 space-y-1 list-disc list-inside font-semibold text-start">
              <li>{t("limitationOfLiabilityHeading")}:</li>

              <ol>
                <li>(i) {t("limitationOfLiabilityPoint_01")};</li>
                <li>(ii) {t("limitationOfLiabilityPoint_02")}; </li>
                <li>(iii) {t("limitationOfLiabilityPoint_03")}; </li>
                <li>(iv) {t("limitationOfLiabilityPoint_04")}; </li>
                <li>(v) {t("limitationOfLiabilityPoint_05")}; </li>
                <li>(vi) {t("limitationOfLiabilityPoint_06")};</li>
              </ol>
            </ul>
          </div>

          <div className="shadow-sm mt-5 text-center">
            <p className="lg:text-2xl text-md font-bold text-[#0081c8] underline">
              {t("indemnification")}
            </p>
            <ul class="ps-5 mt-2 space-y-1 list-disc list-inside font-semibold text-start">
              <li>{t("indemnificationHeading")}.</li>
            </ul>
          </div>

          <div className="shadow-sm mt-5 text-center">
            <p className="lg:text-2xl text-md font-bold text-[#0081c8] underline">
              {t("changeOfTheTerm")}
            </p>
            <ul class="ps-5 mt-2 space-y-1 list-disc list-inside font-semibold text-start">
              <li>{t("changeOfTheTermHeading")}.</li>
            </ul>
          </div>

          <div className="shadow-sm mt-5 text-center">
            <p className="lg:text-2xl text-md font-bold text-[#0081c8] underline">
              {t("contactUs")}
            </p>
            <ul class="ps-5 mt-2 space-y-1 list-disc list-inside font-semibold text-start">
              <li>{t("contactUsDetail")}</li>
            </ul>
          </div>

          {props?.checkBox && (
            <div className="sm:flex-row flex-col sm:gap-0 justify-between items-center my-10">
              <div class="flex items-center">
                <input
                  id="checked-checkbox"
                  onChange={(e) => setIsChecked(e.target.checked)}
                  type="checkbox"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600"
                />
                <label
                  for="checked-checkbox"
                  className="ms-2 text-sm font-medium text-gray-900 "
                >
                  {t("acceptTermsAndConditions")}
                </label>
              </div>
              <button
                disabled={!isChecked}
                onClick={() => handleProceedPayment()}
                className={`${
                  !isChecked ? "bg-gray-400" : "bg-[#0081c8]"
                } border rounded-md uppercase mt-2  font-semibold text-white cursor-pointer py-2 px-1.5 text-sm hover:bg-[#367ba0]`}
              >
                {props?.isLoading
                  ? "Loading..."
                  : props?.isSendMailLoading
                  ? "Loading"
                  : "Proceed To Payment"}
              </button>
            </div>
          )}
        </div>
      </div>
      {!props?.footer && <Footer />}
    </div>
  );
};

export default TermAndConditionForSubscription;
