import FooterBase from "./FooterBase"

export default function FooterIndex() {
  return (
    <main>
      <FooterBase />
    </main>
  )
}

