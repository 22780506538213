import { update } from "react-spring";

export const en = {
  login: "LOGIN",
  logout: "LOGOUT",
  home: "HOME",
  home_description_01:
    "Invite anyone you need to your projects with ease, offering three distinct user access levels: Admin, User, or Guest.",
  home_description_02:
    "Salus Scaffold aspires to be a tool for the scaffolding industry, offering precise control over HSE standards, scaffold quantities, and more. We're dedicated to crafting solutions that align with the needs for you and your company.",
  aboutUs: "ABOUT US",
  services: "SERVICES",
  contactUs: "CONTACT US",
  support: "SUPPORT",
  project: "PROJECT",
  "hero-title": "SALUS SCAFFOLD",
  "hero-description":
    "A user-friendly system designed for the scaffolding industry, which ensures safety and quality.",
  "hero-button_01": "My Projects",
  "hero-button_02": "Create Project",
  "aboutUs-description":
    "At Salus Scaffold, we are dedicated to revolutionizing the scaffolding industry through intuitive technology and deep industry knowledge. Our goal is to empower scaffolders with modern tools that simplify complex tasks and prioritize safety.",
  "aboutUs-button_01": "Read More",
  "demo-heading_01": " A SIMPLE AND SAFE WAY TO APPROVE A SCAFFOLD",
  "demo-heading_02":
    " An intuitive and reliable quality system designed for the building and construction industry, ensuring safety and predictability.",
  "demo-button": "Start Using",
  "our-service-title": "OUR SERVICES",
  "our-service-1": "Material lists",
  "our-service-1-description":
    "Input your own components or select from our existing lists to gain an overview of the weight or quantity of components across your projects. You can effortlessly transfer material lists to your other projects as well.",
  "our-service-2": "Forms",
  "our-service-2-description":
    "Our approval forms allow you to input general information and attach images for documentation purposes. Checklists are filled out to ensure that scaffolds are in proper condition, with the flexibility to easily add points as we understand that scaffolds serve diverse purposes.",
  "our-service-2-1":
    "After control is now 'simpler' than ever with forms that list all your active scaffolds alongside a visual inspection checklist. Plus, it's easy to pinpoint specific locations either by description or image since scaffolds can easily hide on big projects.",
  "our-service-3": "Observations",
  "our-service-3-description":
    "Deviation management with notifications, whether it's an undesirable incident or a positive observation, allows for easy inputting of actions and image function.",
  "our-service-4": "Safe job analysis (SJA)",
  "our-service-4-description":
    "Understanding your task is essential. We break down jobs into subtasks, providing a better understanding of the various tasks involved, either it is equipment transportation, packing, craning, assembly, or disassembly. You can input risks associated with each task.",
  "faq-title": "FREQUENTLY ASKED QUESTIONS",
  "faq-1": "What is Salus Scaffold?",
  "faq-2": "How does Salus Scaffold help me manage scaffolding projects?",
  "faq-3": "Can I invite my team to use Salus Scaffold?",
  "faq-4": "Is my data secure on Salus Scaffold?",
  "faq-5": "How much does it cost to use Salus Scaffold?",
  faq_6: "Contact Us",
  "faq-1-ans":
    "Salus Scaffold is a digital platform designed to help scaffolding companies manage projects efficiently. It includes features like scaffold approval forms, risk assessments, material tracking, and team management, offering a comprehensive solution tailored specifically for the scaffolding industry.",
  "faq-2-ans":
    "Salus Scaffold offers tools such as project planning, scaffold log tracking, HSE reports, and after-control checklists. It allows you to streamline the planning, approval, and documentation processes while keeping your project data organized and easily accessible.",
  "faq-3-ans":
    "Yes! Salus Scaffold allows you to invite users to your projects with different levels of access. You can assign roles like Admin, User, or Guest, giving you full control over who can view, edit, or approve project details.",
  "faq-4-ans":
    "Absolutely. We prioritize your privacy and data security. Salus Scaffold uses encrypted servers and secure authentication protocols to ensure that all your project data and personal information are protected.",
  "faq-5-ans":
    "Salus Scaffold offers flexible subscription plans based on the size of your team. We provide monthly and annual plans for different user tiers, including options for single users, small teams, and larger enterprises. You can also take advantage of our lifetime membership for early adopters at a discounted rate.",
  faq_6_ans:
    "Make a Call +47 902 324 08 Send an Email post@salusscaffold.com   ",
  product: "PRODUCTS",
  "quality-system": "Quality System",
  "central-approval": "Central Approval",
  "HSE-course": "HSE Course",
  contact: "CONTACT",
  "follow-us": "FOLLOW US",
  "PROVIDING-CONSTRUCTIONS":
    "PROVIDING THE BEST QUALITY SERVICES AND CONSTRUCTIONS",
  "We-provide-easier":
    "We provide a digital system designed specifically for the building and construction industry. Our system streamlines and digitizes daily operations through efficient project management, deviation tracking,time recording, and procedural enhancements, making your work easier.",
  SaveTime: "Save Time",
  SaveTimeCompany:
    "Save time when recording material movements between construction yards and construction sites within your company",
  MaterialOnTime: "Material on Time",
  EnsureThatTheTime:
    "Ensure that the right material in the required quantity is on your construction site in time.",
  Transparency: "Transparency",
  IncreaseTheMovements:
    "Increase the transparency of your stocks and your material movements.",
  Utilization: "Utilization",
  IncreaseTheMaterials:
    "Increase the utilization of your own and rented materials.",
  ourClient: "OUR CLIENTS",
  meetTheTeam: "MEET THE TEAM",
  clientReviews: "CLIENT REVIEWS",
  oliverMeyer: "Oliver Meyer",
  academicDirector: "Academic Director",
  description:
    "I recently had the privilege of working with Salus Scaffold Solutions for a construction project, and I can't express how impressed I am with their services. From the moment we reached out for a quote to the completion of the project, they demonstrated an unparalleled level of professionalism, expertise, and commitment to safety. First and foremost, their scaffolding setup was impeccable. The team at Salus Stillas Solutions ensured that our construction site was not only accessible but also secure. Their attention to detail in assembling and inspecting the scaffolding was truly commendable, guaranteeing the safety of everyone on-site.",
  createYourProjectHere: "CREATE YOUR PROJECT HERE",
  searchProjects: "Search Projects",
  loading: "Loading...",
  activeProject: "Active Project",
  completedProject: "Completed Project",
  inactive: "Inactive",
  whatWeDo: "WHAT WE DO",
  atSalusStillas:
    "At Salus Scaffold, our primary goal is to deliver high-quality and secure services to our customers by providing a digital platform for comprehensive management of all your scaffolding work.We are dedicated to advancing the industry through excellence.",
  timeRegistration: "Time Registration",
  checklists: "Checklists",
  forms: "Forms",
  observations: "Observations",
  procedures: "Procedures",
  orders: "Orders",
  pictures: "Pictures",
  filers: "Filers",
  inspections: "Inspections",
  weHelpYou: "WE HELP YOU MANAGE YOUR ALL SCAFFOLDING WORK",
  "Let’sWorkTogether": "Let’s Work Together",
  advancingConstruction: "ADVANCING CONSTRUCTION INDUSTRY",
  deliveringHighQuality:
    "We're dedicated to enhancing construction processes. Through our digital platform, we offer secure and top-quality services, making scaffolding work easier to manage.",
  getAFreeDemo: "GET A FREE DEMO",
  pleaseFillTheBelowForm:
    "Please fill the below form to get information about our Products or Services",
  companyName: "Company Name",
  yourFullName: "Your Full Name",
  mobileNumber: "Mobile Number",
  mailId: "Mail Id",
  privacyPolicy:
    "We require this information to reach out to you and provide you with information about our products and services. Please rest assured, we prioritize your privacy, and your information is kept completely secure with us. You can review our",
  privacyPolicy1: "Privacy Policy",
  and: "and",
  "terms&Conditions": "Terms & Conditions",
  here: "here.",
  getDemo: "Get Demo",
  getInTouchWithUs: "GET IN TOUCH WITH US",
  hereYouWillFind: "Here you will find our contact information",
  makeACall: "Make a Call",
  sendAnEmail: "Send an Email",
  locateUs: "Locate us",
  "salus@stillas.com": "salus@stillas.com",
  dummyAddress:
    "101, Dummy Address, Location,Area Name, City, Country  - Pin Code",
  contactForm: "CONTACT FORM",
  pleaseFillTheBelow: "Please fill the below form to get in touch",
  WhatIsThe: "What is the request is about?",
  select: "Select",
  unitedStates: "United States",
  canada: "Canada",
  france: "France",
  germany: "Germany",
  additionalInformation: "Additional information",
  writeYour: "Write your comments here...",
  followUpOn: "FOLLOW US ON",
  salusStillasSolutions: "Salus Stillas Solutions",
  salusStillas: "Salus_Stillas",
  bestSolutionFor: "BEST SOLUTION FOR CONSTRUCTION INDUSTRY",
  howCanWeHelpYou: "HOW CAN WE HELP YOU TODAY?",
  searchArticles: "Search articles",
  getStarted: "Get Started",
  generalQuestions: "General Questions",
  projects: "Projects",
  hours: "Hours",
  personal: "Personal",
  company: "Company",
  mobileVersion: "Mobile Version",
  integrations: "Integrations",
  question:
    "This is how you get started with the HSE and Quality system as a new customer",
  answer:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec mattis risus. Aenean pulvinar quis nulla vitae ornare. Interdum et malesuada fames ac ante ipsum primis in faucibus. Integer quis mauris vel dolor sodales cursus et et massa. Maecenas sed ullamcorper est. Nullam rhoncus at velit mollis porta. Maecenas maximus sollicitudin diam bibendum pretium.Donec sem mi, rhoncus vel dolor eu, viverra bibendum enim. Suspendisse ante leo, sagittis vitae urna in, accumsan viverra purus. Vivamus molestie sit amet nisi ut venenatis. Curabitur rutrum sollicitudin felis in lacinia. Donec ut velit dui. In venenatis mi sed nisi consectetur sagittis. Sed molestie consequat dictum. Cras vitae mauris nisl. Pellentesque dignissim, massa facilisis lobortis sollicitudin, ante orci rhoncus arcu, vel malesuada lectus tellus eget sem. Vestibulum a hendrerit felis. Curabitur malesuada vehicula arcu, dictum vulputate eros congue vitae. Mauris blandit augue in neque blandit, in egestas elit efficitur. Interdum et malesuada fames ac ante ipsum primis in faucibus. Aenean euismod diam ut nibh molestie laoreet. Pellentesque lacus ligula, tristique eget lobortis nec, blandit at sapien. Praesent ac molestie nulla. Donec vehicula odio ut enim tincidunt semper. Sed malesuada ultricies lectus. Ut ligula magna, vulputate in congue non, egestas vitae massa. Sed consequat maximus porttitor. Aliquam tincidunt non ipsum semper dictum. Cras felis massa, scelerisque nec dolor ut, tempus commodo eros. Proin suscipit ex a diam aliquet, sed placerat sem interdum. Ut blandit velit sed tellus ornare, eu ullamcorper ex fringilla. Suspendisse rutrum egestas ante. Duis a aliquet est. Cras eu fringilla risus. Nunc eu lacus fringilla, volutpat quam fermentum, egestas massa. Fusce aliquam ligula nunc, eu laoreet sem convallis nec.",
  question2: "Does Quality Control have the functions we need?",
  enterProjectName: "Enter Project Name",
  projectNumber: "Project Number:",
  uploadBackground: "Upload Background",
  companyDetails: "COMPANY DETAILS",
  organizationNumber: "Organization Number",
  invoiceAddress: "Invoice Address",
  contactPerson: "Contact Person",
  emailId: "Email Id",
  phoneNumber: "Phone Number",
  otherCompanyDetails: "Other Company Details",
  projectDetails: "PROJECT DETAILS",
  address: "Address",
  otherProjectDetails: "Other Project Details",
  createProject: "CREATE PROJECT",
  "createForm/EditForm": "CREATE FORM / EDIT FORM",
  editYourProject: "Edit Your Project Here or Create Form",
  viewBackground: "View Background",
  invite: "Invite",
  shareThisProject: "SHARE THIS PROJECT",
  sending: "Sending...",
  shareThisProjectViaEmail:
    "Share this project via email, and choose whether the recipient can only view or edit it.",
  writeEmailHere: "Write Email here",
  canView: "Can View",
  canEdit: "Can Edit",
  admin: "Admin",
  status: "Status:",
  createForm: "CREATE FORM",
  approvalForm: "Approval Form",
  scaffoldForm: "Scaffold Form",
  scaffold: "Scaffold",
  projectScaffoldForm: "Project Scaffold Form",
  createScaffoldForm: "Create Scaffold Form",
  observation: "Observation",
  observationDetails: "Observation Detail",
  observationDescription: "Observation Description",
  observerDetail: "Observer Detail",
  materialList: "Material List",
  files: "Files",
  sJA: "Safe-Job-Analysis (SJA)",
  editProject: "EDIT PROJECT",
  safeJobAnalysis: "Safe job analysis",
  projectSafeJOb: "PROJECT SAFE JOB ANALYSIS",
  createSafeJobAnalysis: "Create Safe Job Analysis",
  recentAnalysisList: "Recent Analysis List",
  analysisListName: "Analysis List Name",
  lastEdit: "Last Edit",
  members: "Members",
  addComment: "Add Comment",
  editComment: "Edit Comment",
  commenting: "Commenting...",
  delete: "Delete",
  edit: "Edit",
  save: "Save",
  jobAnalysis: "JOB ANALYSIS",
  projectName: "Project Name",
  dateOfAnalysis: " Date of Analysis",
  "job/TaskDescription": "Job/ Task Description",
  hazardsAndRisks: "Hazards and Risks",
  safetyMeasures: "Safety Measures",
  personalProtectiveEquipment: "Personal Protective Equipment (PPE)",
  "sequenceOfJob/Task": "Sequence of Job/Task",
  responsibleWorkers: "Responsible Workers",
  clearSignature: "Clear Signature",
  addPerson: "Add person",
  dateOfApproval: "DATE OF APPROVAL:",
  signature: "SIGNATURE:",
  "customer’sSignature": "Customer’s Signature",
  AddSignature: "Add Signature",
  signatureOfTheApprover: "Signature of the Approver",
  distribution: "DISTRIBUTION:",
  customerName: "Customer Name",
  otherRelevantParties: "Other Relevant Parties",
  sendforReview: "Send for Review",
  EditSafeJobAnalysis: "Edit Safe Job Analysis",
  material: "MATERIAL",
  projectMaterialList: "PROJECT MATERIAL LIST",
  createMaterialList: "Create Material List",
  recentMaterialList: "Recent Material List",
  materialListName: "Material List Name",
  ourServices: "Our Services",
  listOfMaterials: "LIST OF MATERIALS",
  CustomerContactPerson: "Customer Contact Person",
  materialsOut: "Materials Out",
  materialsIn: "Materials In",
  signatureDeliveryResponsible: "Signature of the Responsible",
  signatureOfTheCustomer: "Signature of the Customer",
  selectScaffoldingSystem: "Select Scaffolding System",
  createCustomList: "Create Custom list",
  selectTheTypeOfScaffolding:
    "Select the type of Scaffolding System you’d like to use:",
  productNo: "Product No.",
  productName: "Product Name",
  descriptionName: "Description",
  weightInKG: "Weight (in KG)",
  date: "Date",
  category: "Category",
  totalWeight: "Total Weight:",
  listOfSelectedMaterials: "List of Selected Materials",
  update: "Update",
  createYourCustomList: "Create your Custom List",
  productDescription: "Product Description",
  enterWeightInKG: "Enter Weight (in KG)",
  enterQuantity: "Enter Quantity",
  add: "Add",
  editMaterialList: "Edit MATERIAL LIST",
  signatureOfTheDeliveryBoy: "Signature of the Responsible",
  projectObservationList: "PROJECT OBSERVATION LIST",
  createObservationList: "Create Observation",
  "observation-title": "Observation Title",
  "What-Is-Observed": "What Is Observed?",
  observationFrom: "Observation From",
  observationDate: "Observation Date",
  observedDetail: "Observed Detail",
  actionTaken: "Action Taken",
  responsibleParty: "Responsible Party",
  editObservation: "Edit Observation",
  upload: "Upload",
  recentPictures: "Recent Pictures",
  searchFiles: "Search Files",
  viewMore: "View More",
  recentFiles: "Recent Files",
  formDatas: "RECENT FORM DETAILS",
  ScaffoldingForm: "Scaffolding Form",
  ScaffoldingItems: "Scaffolding Items",
  ScaffoldingWeight: "Scaffolding Weight",
  Observations: "Observations",
  SJA: "SJA",
  approve: "APPROVE",
  projectApproveForm: "PROJECT APPROVE FORM",
  searchApprovalForm: "Search Approval Form",
  createApprovalForm: "CREATE APPROVAL FORM",
  viewAfterControls: "After Controls Form",
  scaffoldDetail: "SCAFFOLD DETAIL:",
  "scaffoldName/Type": "Scaffold Name / Type",
  WorkOrderNumber: "Work Order Number",
  specificLocation: "Specific Location",
  dateOfInspection: "Date of Inspection",
  generalInformation: "GENERAL INFORMATION:",
  sameAsAfterControlForm: "Same as After Control Form",
  scaffolderOwner: "Scaffolder Owner",
  inspectedBy: "Inspected by",
  builtBy: "Built by",
  userResponsible: "User Responsible",
  maximumWeightPerm2Inkilograms: "maximum weight per m2 in kilograms",
  selectScaffoldClass: "Select Scaffold Class",
  WallAnchorsCapacity: "Wall Anchors Capacity (in KN or KG)",
  amountOfWallAnkers: "Amount of Wall Ankers",
  sizeOfTheScaffold: "Size of the Scaffold",
  visualInspection: "VISUAL INSPECTION:",
  areThereAnySpecific: "ARE THERE ANY SPECIFIC CONDITIONS OR AGREEMENTS",
  comments: "COMMENTS:",
  "writeHere...": "Write Here...",
  signatureOfTheInspector: "Signature of the Inspector",
  applyForApproval: "Apply for Approval",
  controlForm: "Control Form",
  editYourScaffoldHere: "EDIT YOUR SCAFFOLD HERE",
  download: "Download",
  editForm: "Edit Form",
  convertToAfterControlForm: "Convert To After Control Form",
  loginSuccessfully: "Login Successfully",
  pleaseEnterEmail: "Please Enter Email",
  pleaseEnterPassword: "Please Enter Password",
  anErrorOccurredDuringLogin: "An error occurred during login",
  logInToYourAccount: "Log in to your account",
  email: "Email",
  password: "Password",
  forgotPassword: "Forgot password?",
  dontHaveAnAccount: "Don't have an account?",
  registerHere: "Register here",
  privacyPolicySignin: "Privacy policy",
  termsOfUse: "Terms of use.",
  logoutSuccessfully: "Logout Successfully",
  pleaseEnterUserName: "Please Enter UserName",
  registeredSuccessFully: "Registered SuccessFully",
  pleaseFillTheDetailsBelowToRegister:
    "Please fill the details below to register",
  whatDescribesYouBetter: "What describes you better?",
  projectManager: "Project Manager",
  user: "User",
  customer: "Customer",
  next: "Next",
  haveAnAccount: "Have an account?",
  loginHere: "Login Here",
  fullName: "Full Name",
  iUnderstandAndAgreeTo: "I understand and agree to",
  createAccount: "Create Account",
  PleaseEnterCompanyName: "Please enter Company Name",
  pleaseEnterYourFullName: "Please enter Your Full Name",
  pleaseEnterMobileNumber: "Please enter Mobile Number",
  pleaseEnterAvalidEmail: "Please enter a valid email",
  pleaseEnterYourEmail: "Please enter your email",
  projectNameIsRequired: "Project Name is Required",
  companyNameisRequired: "Company Name  is Required",
  organizationNumberIsRequired: "Organization Number is required",
  emailIsRequired: "Email is required",
  pleaseEnterValidEmail: "Please Enter Valid Email !",
  phoneNumberIsRequired: "Phone Number is required",
  invoiceAddressIsrequired: "Invoice Address is required",
  contactPersonIsRequired: "Contact Person is required",
  addressIsRequired: "Address is required",
  startWith4LettersFollowedBy4Digits:
    "start with 4 letters followed by 4 digits",
  projectNumberCannotBeMoreThan8CharactersLong:
    "Project Number cannot be more than 8 characters long",
  projectUpdatedSuccessfully: "Project Updated Successfully",
  failedToUpdateProject: "Failed to update project",
  invalidEmailFormat: "Invalid email format",
  invalidImageFormat: "Invalid Image format",
  pleaseSelectAPermissionLevel: "Please select a permission level",
  projectInvitedSuccessfully: "Project invited Successfully",
  projectCreatedSuccessfully: "Project Created Successfully",
  nameIsRequired: "Name is Required",
  dateIsRequired: "Date is Required",
  controlFormCreatedSuccessfully: "Control Form Created Successfully",
  errorInCreatingControlForm: "Error in creating Control Form",
  signatureIsCompulsoryToProcess: "Signature is compulsory to process",
  anErrorOccurredWhileSubmittingTheForm:
    "An error occurred while submitting the form",
  scaffoldNameIsRequired: "Scaffold Name is Required",
  "scaffoldIdentification/NumberIsRequired":
    "Scaffold Identification/Number is Required",
  scaffolderOwnerIsRequired: "Scaffolder Owner is required",
  inspectedByIsRequired: "Inspected by is required",
  builtByIsRequired: "Built by is required",
  userResponsibleIsRequired: "User Responsible is required",
  locationIsRequired: "Location is Required",
  scaffoldClassIsRequired: "Scaffold Class is required",
  totalWeightPerM2MustBeNumber: "Total Weight Per M2 must be number",
  totalWeightPerM2IsRequired: "Total Weight Per M2 is required",
  amountOfWallAnkersMustBeNumber: "Amount of Wall Ankers must be number",
  amountOfWallAnkersIsRequired: "Amount of Wall Ankers is required",
  wallAnchorsCapacityMustBeANumber: "Wall Anchors Capacity must be a number",
  wallAnchorsCapacityIsRequired: "Wall Anchors Capacity is required",
  sizeOfTheScaffoldMustBeANumber: "Size of the Scaffold must be a number",
  szeOfTheScaffoldIsRequired: "Size of the Scaffold is required",
  updatedsuccessfully: "Updated successfully",
  signatureICompulsoryToProcess:
    "Signature of the Inspector is compulsory to process",
  approvalFormCreatedSuccessfully: "Approval Form Created Successfully",
  checkAboveInformation: "Check above information",
  anErrorOccurredWhileProcessingTheApprovalForm:
    "An error occurred while processing the approval form",
  productNameIsRequired: "Product Name is required",
  weightMustBeANumber: "weight must be a number",
  weightIsRequired: "weight is required",
  weightMustBUnder1: "weight must be under 1",
  customerNameCodeIsRequired: "customerName Code is Required",
  materialListNameIsRequired: "Material List Name is required",
  projectDateIsRequired: "projectDate is required",
  transPorIsRequired: "transport is required",
  saveSuccessfully: "Save Successfully",
  signatureIsCompulsoryToProceeds: "Signature is compulsory to proceeds",
  updateSuccessfully: "Update Successfully",
  saveDataIsCompulsoryToProceeds: "Save data is compulsory to proceeds",
  observationNameIsRequired: "Observation Name is required",
  observationDateIsRequired: "Observation Date is required",
  observedDetailIsRequired: "Observed Detail is required",
  customerNameIsRequired: "Customer Name is required",
  dateOfApprovalIsRequired: "Date of Approval is required",
  observationFormCreatedSuccessfully: "Observation form created successfully",
  thereIsSomeError: "There is some error",
  observationName: "Observation Name",
  safeJobAnalysisNameIsRequired: "Safe Job Analysis Name is required",
  dateOfAnalysisIsRequired: "Date of Analysis is required",
  jobDescriptionIsRequired: "Job Description is required",
  riskIsRequired: "Risk is required",
  safetyMeasuresAreRequired: "Safety Measures are required",
  PPEIsRequired: "PPE is required",
  taskSequenceIsRequired: "Task Sequence is required",
  customerNameAndSignatureAreRequired:
    "Customer name and signature are required",
  approverNameAndSignatureAreRequired:
    "Approver name and signature are required",
  atLeastOnePersonSignatureIsRequired:
    "At least one person signature is required",
  safeJobAnalysisCreatedSuccessfully: "Safe Job Analysis created successfully",
  failedToCreateSafeJobAnalysis: "Failed to create Safe Job Analysis",
  safeJobAnalysisName: "Safe Job Analysis Name",
  observationEditedSuccessfully: "Observation Edited successfully",
  errorUploadingImagePleaseTryAgain: "Error uploading image. Please try again.",
  afterControlForm: "After Control Form",
  deletedSuccessfully: "deleted successfully",
  afterControlFormList: "After Control Form List",
  projectNo: "Project No.",
  nofileAvailable: "No File Available",
  editAfterControlForm: "EDIT AFTER CONTROL FORM",
  yourMessage: "Your message",
  controlFormDetails: "CONTROL FORM DETAILS:",
  recheckResponsibleSignature: "Recheck Responsible Signature",
  userResponsibleSignature: "User Responsible Signature",
  "Scaffold Name": "Scaffold Name",
  approvalFormStatusUpdatedSuccessfully:
    "Approval form status updated successfully",
  setAsActive: "Set As Active",
  setAsDisassembled: "Set As Disassembled",
  setAsInactive: "Set As Inactive",
  viewAll: "View All",
  viewLess: "View Less",
  setAsCompleted: "Set As Completed",
  noProjectAvailable: "No Project Available",
  "prevention-of-recurrence": "Prevention Of Recurrence",
  "save-approval": "Save Approval",
  "active-scaffolds": "Active Scaffolds",
  "safe-job-analysis": "Safe Job Analysis",
  "square-meters": "Square Meters (m2)",
  "cubic-meters": "Cubic Meters (m3)",
  "length-meters": "Length Meters (LM)",
  "height-meters": "Height Meters (HM)",
  ObservationName: "Observation Name",
  "Here,describetheincidentorobservationindetail":
    "Here, describe the incident or observation in detail",
  Describetheactionsthatshouldbetakeninresponsetotheobservation:
    "Describe the actions that should be taken in response to the observation",
  "Specifywhoisresponsibleforimplementingtheactions.":
    "Specify who is responsible for implementing the actions.",
  LatestObservations: "Latest Observations",
  UnderProgress: "Under Progress",
  ClosedObservations: "Closed Observations",
  LatestMaterialLists: "Latest Material Lists",
  UnderProgessMaterialLists: "Progess List",
  closedMaterialLists: "Close List",
  MaterialListName: "Material List Name",
  ViewMore: " View More",
  Searchformaterial: "Search for material",
  SaveForm: "Save Form",
  "Outlinethestep-by-stepsequenceforcompletingthejob/task":
    "Outline the step-by-step sequence for completing the job/task",
  "SpecifyanyrequiredPPEforthisjob/task.":
    "Specify any required PPE for this job/task.",
  Listsafetymeasuresinplaceortobeimplementedtomitigateidentifiedhazards:
    "List safety measures in place or to be implemented to mitigate identified hazards",
  SafeJobAnalysisName: "Safe Job Analysis Name",
  ResponsibleforScaffold: "Responsible for Scaffold",
  view: "View",
  Save: "Save",
  Admin: "Admin",
  Guest: "Guest",
  User: "User",
  FromProject: "From Project",
  ToProject: "To Project",
  SAVEPROJECT: "SAVE PROJECT",
  createAfterControlForm: "Create After Control Form",
  editafterControlForm: "Edit After Control Form",
  CompletedControlFormList: "Completed After Control Form",
  InprogressControlFormList: "Inprogress After Control Form",
  "ScaffoldName/type": "Scaffold Name/type",
  "ScaffoldID/Number": "Scaffold ID/Number",
  Specificlocation: "Specific location",
  Control: "Control",
  LastInspection: "Last Inspection",
  AddComment: "Add Comment",
  VisualInspection: "Visual Inspection",
  Yes: "Yes",
  No: "No",
  "Active-scaffolds": "Active-scaffolds",
  "Inactive-scaffolds": "Inactive-scaffolds",
  Activescaffolds: "Active scaffolds",
  SubmitData: "Create After control",
  CustomerSignature: "Customer Signature",
  ApproverName: "Approver Name",
  ApprovalSignature: "Approval Signature",
  DownloadPDF: "Download PDF",
  observationFormEditSuccessfully: "observation Form Edit Successfully",
  imageRequired: "What is Observed? image is requied",
  "Yourdiscipline?": "Your discipline ?",
  Observationisrelatedto: "Observation is related to",
  norelevantcategory: "no relevant category",
  Orderandtidiness: "Order and tidiness",
  "Physicalworkingenvironment/Ergonomics":
    "Physical working environment/Ergonomics",
  Positivefeedback: "Positive feedback",
  Riskybehavior: "Risky behavior",
  "Teamwork/Collaboration": "Teamwork/Collaboration",
  Workmethodorprocedures: "Work method or rocedures",
  Didyoutakeaction: "Did you take action",
  Describewhatactionyoudid: "Describe what action you did",
  "Isthereneedforfurtheraction?": "Is there need for further action?",
  "Describewhatfurtheractionyoudid?": "Describe what further action you did",
  Yourname: "Your Name",
  Youremailaddress: "Your email address",
  ViewObservation: "View Observation",
  UploadObservation: "Upload Observation",
  Uploading: "Uploading.....",
  Enterotptologinwhichissendtoyouremail:
    "Enter otp to login which is send to your email",
  EnterOTP: "Enter OTP",
  ResendOTP: "Resend OTP",
  OTPResentSuccessfully: "OTP Resent Successfully",
  "OTP has been sent to your email. Use it to complete the login.":
    "OTP has been sent to your email. Use it to complete the login.",
  VerifyOTP: "Verify OTP",
  ForgetPassword: "Forget Password",
  ChangePassword:"Change Password",
  ConfirmPassword: "Confirm Password",
  change_password_header: "Enter Email To Change Password",
  "Password reset successfully": "Password reset successfully",
  "OTP has been sent to your email. Use it to Forget password.":
    "OTP has been sent to your email. Use it to Forget password.",
  Calender: "Calender",
  agenda: "agenda",
  TeamleaderSignature: "Team leader Signature",
  WorkDescription: "Work Description",
  AddTask: "Add Task",
  Add: "Add",
  Subtask: "Sub task",
  Risk: "Risk",
  Measure: "Measure",
  AddMore: "Add More",
  "Select a Type": "Select a Type",
  "Standard scaffold": "Standard scaffold",
  "Fasade scaffold": "Fasade scaffold",
  "Hanging scaffold": "Hanging scaffold",
  "Rolling scaffold": "Rolling scaffold",
  "Support scaffolding": "Support scaffolding",
  Other: "Other",
  ScaffoldName: "Scaffold Name",
  workDescriptionIsRequired: "Work Description Is Required",
  "Enter visual inspection": "Enter visual inspection",
  Class1: "Class 1",
  Class2: "Class 2",
  Class3: "Class 3",
  Class4: "Class 4",
  Class5: "Class 5",
  Class6: "Class 6",
  SelectaUnit: "Select a Unit",
  AnchorCapacityUnit: "Anchor Capacity Unit",
  SizeofScaffold: "Size of Scaffold",
  "Writeyourthoughtshere...": "Write your thoughts here...",
  EditComment: "Edit Comment",
  "UploadPhoto/File": "Upload Photo/File",
  ViewsDocument: "Views Document",
  Yourmessage: "Your message",
  comingsoon: "Coming Soon",
  mycompany: "My Company",
  home_scaffold_welcome: "Welcome to Salus Scaffold",
  SendMessage: "Send Message",
  productNumber: "Product Number",
  scaffoldLogs: "Scaffold Manage",
  subscription: "SUBSCRIPTION",
  my_subscription: "MY COMPANY",
  uploadCalculations: "Upload Calculations",
  today: "Today's",
  week: "Week",
  month: "Month",
  year: "Year",
  workTaskTitle: "Work Task Title",
  workTask: "Work Task",
  emergencyProcedure: "Emergency Procedures",
  safetyChecklist: "Safety Checklist",
  requirementPermit: "Required Permits",
  riskLevel: "Risk Level",
  protectiveEquipment: "Protective Equipment",
  addNewProtectiveEquipment: "Add New Protective Equipment",
  addNewRequiredPermits: "Add New Required Permits",
  typeOfWork: "Type Of Work",
  addNewTypeOfWork: "Add New Type Of Work",
  workInHeights: "Work In Heights",
  designatedWorkArea: "Designated Work Area",
  addNewDesignatedWorkArea: "Add New Designated Work Area",
  observationOverview: "Observation Overview",
  needsAction: "Needs Action",
  underControl: "Under Control",
  completedObservation: "Completed Observation",
  observationDetail: "Recent Observation Detail",
  observationNeedAction: "Observation Need Action",
  NeedAction: "Need Action",
  observationUnderControl: "Observation Under Control",
  UnderControl: "Under Control",
  observationCompleted: "Observation Completed",
  observationCategory: "Observation Category",
  safety: "Safety",
  quality: "Quality",
  environmental: "Environmental",
  efficiency: "Efficiency",
  equipment: "Equipment",
  positive: "positive",
  other: "Other",
  mediaAttachment: "Media Attachment",
  photos_videos: "Photos/Videos",

  termAndCondition: "Term And Condition",
  termAndConditionHeading: "Terms and Conditions for Subscription Plans",
  introduction: "Introduction",
  introductionHeading: `Welcome to Salus Scaffold. These Terms and Conditions "(Terms)" govern your use of our services and subscription plans ("Service") offered through our platform salusscaffold.com. By subscribing to and using our Service, you agree to comply with and be bound by these Terms.`,
  subscriptionPlans: "Subscription Plans",
  planOptions: "Plan Options",
  planOptionHeading:
    "We offer various subscription plans, including but not limited to each with specific features and pricing.",
  eligibility: "Eligibility",
  eligibilityHeading:
    "You must be at least 18 years old to subscribe to our Service.",
  accountRegistration: "Account Registration",
  accountRegistrationHeading:
    "To subscribe, you must create an account on our Site. You are responsible for maintaining the confidentiality of your account information and password",
  billingAndPayment: "Billing and Payment",
  paymentMethod: "Payment Methods",
  paymentMethodHeading:
    "We accept various payment methods, including credit cards, PayPal, and other online payment systems.",
  billingCycle: "Billing Cycle",
  billingCycleHeading:
    "Subscriptions are billed on a monthly or annual basis, depending on the plan you choose",
  automaticRenewal: "Automatic Renewal",
  automaticRenewalHeading:
    "Subscriptions automatically renew at the end of each billing cycle unless you cancel your subscription before the renewal date",
  priceChanges: "Price Changes",
  priceChangesHeading:
    "We reserve the right to change subscription fees at any time. We will notify you of any price changes in advance. Continued use of the Service after the price change takes effect constitutes your agreement to the new fees",
  refund: "Refunds",
  refundHeading:
    "Subscription fees are non-refundable. However, we may provide refunds or credits at our sole discretion under certain circumstances",
  cancellationAndTermination: "Cancellations and Termination",
  cancellationByYou: "Cancellation by You",
  cancellationByYouHeading:
    "You can cancel your subscription at any time through your account settings on our Site. Cancellations take effect at the end of the current billing cycle",
  terminationByUs: "Termination by Us",
  terminationByUsHeading:
    "We may suspend or terminate your account and access to the Service if you violate these Terms or engage in fraudulent, abusive, or illegal activities",
  userConduct: "User Conduct",
  prohibitedActivity: "Prohibited Activities",
  prohibitedActivityHeading:
    "You agree not to use the Service for any unlawful purpose or in a way that violates these Terms",
  accountSecurity: "Account Security",
  accountSecurityHeading:
    "You are responsible for all activities that occur under your account. You must notify us immediately of any unauthorized use of your account",
  accountSharing: "Account Sharing",
  accountSharingHeading:
    "Your account is yours alone, you are not to share or accept others logging information",
  intellectProperty: "Intellectual Property",
  ownerships: "Ownerships",
  ownershipsHeading:
    "We own all rights, title, and interest in and to the Service, including all intellectual property rights",
  license: "License",
  licenseHeading:
    "We grant you a limited, non-exclusive, non-transferable license to use the Service for your personal or internal business purposes",
  privacy: "Privacy",
  privacyHeading:
    "Your privacy is important to us. When you use our services, you're trusting us with your information. We understand this is a big responsibility and work hard to protect your information and put you in control",
  limitationOfLiability: "Limitation of Liability",
  limitationOfLiabilityHeading:
    "To the fullest extent permitted by law, Salus Scaffold shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from",
  limitationOfLiabilityPoint_01: "your use or inability to use the Service",
  limitationOfLiabilityPoint_02:
    "any unauthorized access to or use of our servers and/or any personal information stored therein",
  limitationOfLiabilityPoint_03:
    "any interruption or cessation of transmission to or from the Service",
  limitationOfLiabilityPoint_04:
    "any bugs, viruses, trojan horses, or the like that may be transmitted to or through our Service by any third party",
  limitationOfLiabilityPoint_05:
    "any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the Service; and/or",
  limitationOfLiabilityPoint_06:
    "the defamatory, offensive, or illegal conduct of any third party. In no event shall Salus Scaffold's aggregate liability for all claims related to the Service exceed the amount paid by you to Salus Scaffold for the Service in the twelve (12) months preceding the date of the event giving rise to the liability",
  indemnification: "Indemnification",
  indemnificationHeading:
    "You agree to indemnify, defend, and hold harmless Salus Scaffold, its affiliates, and their respective officers, directors, employees, agents, and representatives from and against any claims, liabilities, damages, losses, costs, and expenses, including reasonable attorneys' fees, arising out of or in any way connected with your use of the Service or your violation of these Terms",
  changeOfTheTerm: "Changes to the Terms",
  changeOfTheTermHeading:
    "We may update these Terms from time to time. We will notify you of any changes by posting the new Terms on our Site. You are advised to review these Terms periodically for any changes. Your continued use of the Service after the changes take effect constitutes your acceptance of the new Terms",
  contactUsDetail:
    "If you have any questions about these Terms, please contact us at post@salsusscaffold.com",
  acceptTermsAndConditions: "Accept terms and conditions",
  describeAction: "Describe Action?",
  describeFurtherAction: "Is there need for further action?",
  riskAssessment: "Risk Assessment",
  footer_bottom_text: "2024 Salus Scaffold, All rights reserved",
  change_password: "Change Password",
  after_control_form_list: "After Control Form List",
  control_name: "Control Name",
  created_at: "Created At",
  updated_at: "Updated At",
  previous: "Previous",
  priceManager: "Price Manager",
  start_date: "Start Date",
  end_date: "End Date",
  fileUploadedSuccessfully: "File Uploaded Successfully",
  imageUploadedSuccessfully: "Picture Uploaded Successfully",
  select_date: "Select Date",
  filter_option: "Filter Option",

  all: "All",
  active: "Active",
  dismantle: "Dismantled",

  scaffold_log_search:
    "Search  Specific Location, Scaffold Number, Scaffold Name, Date",

  units: "Units",
  total: "Total",

  onlyNumber: "Only Enter Number",
  addSubtask: "Add Subtask",
  extendedList: "Extended Check List",

  subscription_description_01: "Choose Plan That Fits For You",
  subscription_description_02:
    "Simple, transparent pricing that grows with you.",
  subscription_popular: "Popular",
  subscription_monthly: "Monthly",
  subscription_yearly: "Yearly",
  subscription_get_started: "Get Started",
  subscription_confirmation:
    "Are you sure you wants to purchase the subscription?",
  subscription_cancel: "Cancel",
  subscription_loading: "Loading",
  subscription_confirm: "Confirm",
  subscription_info_01: "You Already have Subscription Plan",
  subscription_go_back: "Go Back",

  subscription_confirmation_01: "Subscription Confirmation",
  subscription_confirmation_detail: "Subscription Plan: 25+ Users",
  subscription_confirmation_description: "Description",
  subscription_confirmation_note:
    "Thank you for choosing our 25+ Users Subscription Plan!",
  subscription_confirmation_body_01:
    "We understand the unique needs of large teams and are committed to providing you with the best service and enterprise-level security. Our team will reach out to you shortly to discuss your requirements in detail and offer a tailored solution that meets your specific needs.",
  subscription_confirmation_body_02:
    "Please expect a call from us soon. We are dedicated to ensuring you receive the best value and service for your investment.",

  service_material: "Material List",
  service_my_subscription: "My Subscription",
  service_subscription: "Subscription",
  service_change_password: "Change Password",
  service_subscription_header: "SUBSCRIPTION",
  service_subscription_title: "Welcome to Salus Scaffold!",

  my_subscription_header: "MY SUBSCRIPTION",
  my_subscription_status: "Not Subscribed",
  my_subscription_plan_text: "subscription plan",
  my_subscription_start: "Subscription Start",
  my_subscription_end: "Subscription End",
  my_subscription_remaining: "Remaining Days",
  my_subscription_days_left: "Days Left",
  my_subscription_active_user: "Active Users",
  my_subscription_remaining_users: "Remaining Users",
  my_subscription_users: "users",
  my_subscription_view_users: "View Users",
  my_subscription_add_users: "Add Users",
  my_subscription_cancel: "Cancel Subscription",
  my_subscription_userName: "User Name",
  my_subscription_userEmail: "User Email",
  my_subscription_userPhone: "Phone no.",
  my_subscription_userCompany: "Company",
  my_subscription_userPassword: "password",
  my_subscription_list: "List Of Subscribed Users",
  my_subscription_access_granted: "Access Status",
  my_subscription_access_date: "Date of Access",
  my_subscription_access_status: "Date of Access",
  my_subscription_access_edit: "Edit",
  my_subscription_access_active: "Active",
  my_subscription_access_inactive: "Inactive",
  my_subscription_data_status: "No Data Available",
  user_guide: "User Guide",
  sja_hard_hat: "Hard Hat",
  sja_safety_glassess: "Safety Glasses",
  sja_gloves: "Gloves",
  sja_safety_boots: "Safety Boots",
  sja_high_visibility_cloths: "High-Visibility Clothes",
  sja_hearing_protection: "Hearing Protection",
  sja_dust_mask: "Dust/Filter Mask",
  sja_protection_harness: "Protection Harness",
  sja_work_height: "Work In Heights",
  sja_confined_space: "Confined Space",
  sja_hot_work: "Hot work",
  sja_cordon: "Cordon off area",
  sja_question_01: "Is there sufficient lighting?",
  sja_question_02: "Does the work team have the right skills for the task?",
  sja_question_03:
    "Does everyone on the work team agree that the job can be done safely?",
  sja_question_04: "Is there other work in the area?",
  custom_list: "Custom List",
  invited_person: "List of invited person",
  my_subscriber: "my subscriber",
  emil: "Email",
  position: "Position",
  quick_invite: "Quick Invite",
  remove_invite: "Remove Invite",
  no_data_found: "No Data Found",
  user_manual: "User Manual",
  per_month_per_user: "per month per user",
  per_year_per_user: "per year per user",
  size_of_scaffold: "Size of Scaffold",
  name: "Name",
  unit: "Unit",
  calculation: "Calculation",
  size: "Size",
  sq_meter: "square meter",
  cu_meter: "cubic meter",
  ln_meter: "Length meter",
  add_more: "Add More",
  remove: "Remove",
  length: "Length",
  width: "Width",
  height: "Height",

  my_company: "My Company",
  actions: "Actions",
  about_1_title: "Welcome to Salus Scaffold!",
  about_1_desc:
    "At Salus Scaffold, we are dedicated to revolutionizing the scaffolding industry through intuitive technology and deep industry knowledge. Our goal is to empower scaffolders with modern tools that simplify complex tasks and prioritize safety.",
  about_2_title: "Our Story",
  about_2_desc:
    "Born out of the need for a tailored solution to scaffolding challenges, Salus Scaffold was developed by a group of industry professionals who understand the intricate needs of the trade. We saw an opportunity to make scaffold management more efficient, safer, and less reliant on outdated systems. Our team brings together experts in construction, scaffolding, and technology to build a platform that addresses real problems in the industry.",
  about_3_title: "What We Offer?",
  about_3_desc:
    "Salus Scaffold is a comprehensive platform designed to manage scaffolding projects from start to finish. Whether it’s scaffold inspections, material tracking, risk assessments, or job site reports, we bring all these critical tasks together into one easy-to-use system. Some of our key features include",
  about_3_list_1:
    "Scaffold Inspection & Approval",
  about_3_list_1_1:"Easily conduct and document scaffold inspections.",
  about_3_list_2:
    "Project Management",
  about_3_list_2_1:"Track ongoing projects, assign tasks, and monitor progress in real-time.",
  about_3_list_3:
    "Material Control",
  about_3_list_3_1:"Create custom material lists and manage your inventory seamlessly.",
  about_3_list_4:
    "Risk Assessments",
  about_3_list_4_1:"Generate detailed safety plans that ensure compliance and mitigate risks.",
  about_3_list_5:
    "Scaffold Logs",
  about_3_list_5_1:"Access historical data and reports from previous jobs to inform future projects.",
  about_4_title: "Why We Stand Out",
  about_4_desc:
    "We know that scaffolding professionals have been underserved by generic construction software for too long. With Salus Scaffold, we’ve built a solution specifically for your trade, ensuring every feature is tailored to the unique demands of scaffolding work. By bridging the gap between technology and trade expertise, we aim to improve safety, streamline operations, and boost productivity on every job site.",
  about_5_title: "Our Vision",
  about_5_desc:
    "Our vision is simple: to make scaffold management smarter, safer, and more efficient. We believe that the future of scaffolding is digital, and we are committed to providing tools that meet and exceed the evolving needs of the industry.",
  about_6_title: "Get Involved",
  about_6_desc:
    "We invite scaffolders, project managers, and business owners alike to join us in creating a safer and more organized future. Whether you’re a small team or managing multiple large-scale projects, Salus Scaffold is built to grow with you.",
  join_us_msg:"Let’s build the future of scaffolding together.",
  access_model_title: "Please Select the role for this email",
  confirmBtn: "Confirm",
  cancelBtn: "Cancel",
  loadingTxt: "Loading...",
  buildAccording: "Build according to user guide",
  buildDay: "Build Day",
  dismantleDay: "Dismantal Day",
  adminAccessStatus : "Only admin can change the project status",
  Scaffolding_Pricing_Management:"Scaffold Pricing Management"
};
