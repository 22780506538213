import React, { useEffect, useState } from "react";
import {
  getMaterialListAdmin,
  getMaterialListUser,
  getNotification,
  updateMaterialListWithProjectService,
} from "../../Services/materialListWithProjectService";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { IoClose } from "react-icons/io5";
import {
  deleteNotification,
  getActionLogsList,
} from "../../Services/actionLogsService";
const backgroundImageUrl = require("../../Assets/background_image.jpg");
const noNotification = require("../../Assets/no_notification.jpg");

export const ModalNotification = ({ setIsNotificationModelOpen }) => {
  const notification = useSelector(
    (state) => state?.materialListWithProject?.notification
  );

  const [tranferOpen, setTransferOpen] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingAdmin, setLoadingAdmin] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);
  const [actionLogs, setActionLogs] = useState([]);
  const [currentDeletedId, setCurrentDeletedId] = useState("");

  const fetchNotification = async () => {
    setLoading(true);
    try {
      await getNotification();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getActionLogs = async () => {
    try {
      const actionLogsResponse = await getActionLogsList();

      setActionLogs(actionLogsResponse?.data);
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    fetchNotification();
    // getActionLogs();
  }, []);

  const handelTransfer = () => {
    setTransferOpen(!tranferOpen);
  };
  const adminApprovalData = useSelector(
    (state) => state?.materialListWithProject?.materialListsAdmin
  );

  console.log("adminApprovalData", adminApprovalData);
  const roleOfUser = useSelector((state) => state?.auth?.loggedInUser?.type);
  const fetchAdminApproval = async () => {
    setLoadingAdmin(true);
    try {
      await getMaterialListAdmin();
    } catch (error) {
    } finally {
      setLoadingAdmin(false);
    }
  };
  useEffect(() => {
    fetchAdminApproval();
  }, []);

  const handlePermissionResponse = async (res, id) => {
    if (res === "Yes") {
      const response = await updateMaterialListWithProjectService(id, {
        permisssionAdminToUser: true,
        transferMaterialListsPermisssion: true,
        permisssionAdminUserMessage: "approved",
      });
      toast.success("Approved");
    }
    if (res === "No") {
      const response = await updateMaterialListWithProjectService(id, {
        permisssionAdminToUser: true,
        transferMaterialListsPermisssion: false,
        permisssionAdminUserMessage: "decline",
      });
      toast.error("decline");
    }
  };
  const fetchUserApproval = async () => {
    setLoadingUser(true);
    try {
      await getMaterialListUser();
    } catch (error) {
    } finally {
      setLoadingUser(false);
    }
  };
  useEffect(() => {
    fetchUserApproval();
  }, []);
  const userApprovalData = useSelector(
    (state) => state?.materialListWithProject?.materialListsUser
  );

  function timeSince(timestamp) {
    const now = new Date();
    const past = new Date(timestamp);
    const diffInSeconds = Math.floor((now - past) / 1000);

    const units = [
      { name: "year", seconds: 60 * 60 * 24 * 365 },
      { name: "month", seconds: 60 * 60 * 24 * 30 },
      { name: "week", seconds: 60 * 60 * 24 * 7 },
      { name: "day", seconds: 60 * 60 * 24 },
      { name: "hour", seconds: 60 * 60 },
      { name: "minute", seconds: 60 },
      { name: "second", seconds: 1 },
    ];

    for (const unit of units) {
      const quotient = Math.floor(diffInSeconds / unit.seconds);
      if (quotient >= 1) {
        return `${quotient} ${unit.name}${quotient > 1 ? "s" : ""} ago`;
      }
    }

    return "just now";
  }

  const handleNotificationDelete = async (id) => {
    const deleteNotificationResponse = await deleteNotification(id);
    if (deleteNotificationResponse?.status === 200 || true) {
      toast.success("Notification deleted successfully");
    }
    getActionLogs();
  };

  return (
    <div
      className="fixed top-0 right-0 w-[100vw] h-full z-50 border flex justify-end translate-x-0 ease-in-out animated-element"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabIndex="-1"
    >
      <div className="border-0 rounded-lg relative flex justify-end flex-col w-[35vw] min-w-[400px]   outline-none focus:outline-none h-full">
        <IoClose
          className="absolute top-[2%] right-[2%] text-[26px] cursor-pointer rounded-full border-2 font-bold text-black  border-black z-50"
          type="button"
          onClick={() => setIsNotificationModelOpen(false)}
        />
        <div className="relative p-6 flex-auto bg-white w-auto">
          {roleOfUser === 0 ? (
            <div role="tablist" className="tabs tabs-bordered">
              <p
                role="tab"
                className={`tab ${tranferOpen ? "" : "tab-active"}`}
                onClick={() => {
                  handelTransfer();
                  setShowNotification(!showNotification);
                }}
              >
                Notification
              </p>
              {/* <p
                role="tab"
                className={`tab ${tranferOpen ? "tab-active" : ""}`}
                onClick={() => {
                  handelTransfer();
                  setShowNotification(!showNotification);
                }}
              >
                Transfer Material
              </p> */}
            </div>
          ) : null}

          <div
            className="relative text-gray-700 block py-2 text-sm w-full h-full overflow-auto pb-10"
            role="menuitem"
          >
            {tranferOpen ? (
              <>
                {roleOfUser === 0 ? (
                  <>
                    <div className="relative flex-auto w-full h-full ">
                      <div className="">
                        <p className="text-md font-bold">
                          Permission to user for transfer material lists
                        </p>

                        <div>
                          {loadingAdmin ? (
                            <div className="text-center w-full">
                              <svg
                                aria-hidden="true"
                                className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                  fill="currentFill"
                                />
                              </svg>
                            </div>
                          ) : (
                            <div
                              className={`${
                                adminApprovalData?.length > 3 ? "h-full " : ""
                              } `}
                            >
                              {adminApprovalData?.length > 0 ? (
                                adminApprovalData?.map((el, index) => (
                                  <div
                                    key={index}
                                    className="border  rounded-md mb-2 p-2 bg-white"
                                  >
                                    <p className="text-base">
                                      {"materialListName"}{" "}
                                      <span className="italic text-lg font-medium">
                                        {el.materialListName}
                                      </span>
                                    </p>
                                    <div className="flex items-center justify-between px-6 py-4 rounded-b">
                                      <button
                                        className={`text-green-500 hover:bg-gray-100 border-2 rounded-xl border-solid border-blueGray-200 bg-transparent font-bold uppercase px-4 py-2 text-xs outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 ${
                                          el?.permisssionAdminUserMessage ===
                                            "approved" ||
                                          el?.permisssionAdminUserMessage ===
                                            "decline"
                                            ? "bg-gray-300 cursor-not-allowed"
                                            : ""
                                        }`}
                                        type="button"
                                        onClick={() =>
                                          handlePermissionResponse(
                                            "Yes",
                                            el._id
                                          )
                                        }
                                        disabled={
                                          el?.permisssionAdminUserMessage ===
                                            "approved" ||
                                          el?.permisssionAdminUserMessage ===
                                            "decline"
                                        }
                                      >
                                        {el?.permisssionAdminUserMessage ===
                                        "approved"
                                          ? "Approved"
                                          : "Approve"}
                                      </button>

                                      <button
                                        className={`text-red-500 hover:bg-gray-100 border-2 rounded-xl border-solid border-blueGray-200 bg-transparent font-bold uppercase px-4 py-2 text-xs outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 ${
                                          el?.permisssionAdminUserMessage ===
                                            "approved" ||
                                          el?.permisssionAdminUserMessage ===
                                            "decline"
                                            ? "bg-gray-300 cursor-not-allowed"
                                            : ""
                                        }`}
                                        type="button"
                                        onClick={() =>
                                          handlePermissionResponse("No", el._id)
                                        }
                                        disabled={
                                          el?.permisssionAdminUserMessage ===
                                            "approved" ||
                                          el?.permisssionAdminUserMessage ===
                                            "decline"
                                        }
                                      >
                                        Decline
                                      </button>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <div>No Notification available</div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </>
            ) : (
              <>
                {roleOfUser === 0 ? (
                  <div className="w-full h-full">
                    {loadingUser ? (
                      <div className=" w-full h-full flex flex-col justify-center items-center m-auto">
                        <svg
                          aria-hidden="true"
                          className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        <p className="text-xl font-bold">Loading...</p>
                      </div>
                    ) : (
                      <div
                        className="absolute top-0 flex gap-4 flex-col justify-center items-center"
                        style={{ width: "-webkit-fill-available" }}
                      >
                        {actionLogs?.length > 0 ? (
                          actionLogs?.map((notification, index) => (
                            <div
                              className={`relative p-[10px] bg-white rounded-lg shadow w-full border pt-5 ${
                                currentDeletedId === notification?._id &&
                                "opacity-5"
                              }`}
                            >
                              <IoClose
                                className="absolute top-[2%] right-[2%] text-[16px] cursor-pointer rounded-full border font-semibold text-black  border-black z-50"
                                type="button"
                                onClick={() => {
                                  setCurrentDeletedId(notification?._id);
                                  handleNotificationDelete(notification?._id);
                                }}
                              />

                              <div className=" inline-flex items-center justify-between w-full">
                                <div className="inline-flex items-center">
                                  <img
                                    src="https://cdn-icons-png.flaticon.com/128/763/763812.png"
                                    alt="Training Icon"
                                    className="w-6 h-6 mr-3"
                                  />
                                  <h3 className="font-bold text-base text-gray-800">
                                    {notification?.userId?.name}
                                  </h3>
                                </div>
                                <p className="text-xs text-gray-500">
                                  {timeSince(notification?.createdAt)}
                                </p>
                              </div>
                              <p className="mt-1 text-md font-semibold">
                                Created{" "}
                                {notification?.isApprovalDetail
                                  ? "Approval Form"
                                  : notification?.isMaterialListDetail
                                  ? "Material List"
                                  : notification?.isEditMaterialListDetail
                                  ? "Edit Material List"
                                  : notification?.isEditApprovalDetail
                                  ? "Edit Approval Form"
                                  : notification?.isObservation
                                  ? "Observation Form"
                                  : "Edit Observation"}
                                <br></br>
                                {notification?.isApprovalDetail
                                  ? `Id: ${notification?.approvalFormDetail?.scaffoldIdentificationNumber}`
                                  : notification?.isMaterialListDetail
                                  ? `Id: ${notification?.materialListDetail?.scaffoldIdentificationNumber}`
                                  : notification?.isEditApprovalDetail
                                  ? `Id: ${notification?.approvalFormDetail?.scaffoldIdentificationNumber}`
                                  : notification?.isEditApprovalDetail
                                  ? `Id: ${notification?.materialListDetail?.scaffoldIdentificationNumber}`
                                  : notification?.isObservationEdit
                                  ? `Status: ${notification?.observationStatus} `
                                  : `Title : ${notification?.observationId?.observerDetails?.observerDetail}`}
                                <br></br>
                                <span className=" uppercase">
                                  On Project:{" "}
                                  {notification?.projectId?.projectName}
                                </span>
                              </p>
                            </div>
                          ))
                        ) : (
                          <div className=" flex flex-col justify-center items-center m-auto">
                            <div className="text-lg font-semibold mt-10">
                              {" "}
                              No Notification available
                            </div>
                            <img alt="" src={noNotification} />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  <>
                    {roleOfUser === 1 ? (
                      <div>
                        <p className="text-xl">Notification</p>
                        {loading ? (
                          <div className="text-center w-full">
                            <svg
                              aria-hidden="true"
                              className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                          </div>
                        ) : (
                          <div
                            className={`${
                              userApprovalData?.length > 4
                                ? "h-[400px] overflow-auto"
                                : ""
                            } `}
                          >
                            {userApprovalData?.length > 0 ? (
                              userApprovalData?.map((el, index) => (
                                <div
                                  key={index}
                                  className="text-gray-700 block px-2 py-2 text-sm hover:bg-gray-100 border-b-2"
                                  role="menuitem"
                                >
                                  <p className="font-medium text-md">
                                    {"materialListName"}{" "}
                                    <span className="italic text-base font-semibold">
                                      {el.materialListName}
                                    </span>
                                    <p
                                      className={
                                        el?.permisssionAdminUserMessage ===
                                        "approved"
                                          ? "text-green-700 text-md font-medium"
                                          : "text-red-600 text-base font-medium"
                                      }
                                    >
                                      {" "}
                                      {"status:"}{" "}
                                      <span className="font-semibold">
                                        {" "}
                                        {el?.permisssionAdminUserMessage}
                                      </span>
                                    </p>
                                  </p>
                                </div>
                              ))
                            ) : (
                              <div>No Notification available</div>
                            )}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div>No Notification available</div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
