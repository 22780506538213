import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import {
  googleLoginService,
  googleSignUpService,
  sendRegisterOtp,
  signUpService,
} from "../Services/authService";
import { useSelector } from "react-redux";
import { t } from "../utils/translate";
import { AiOutlinePhone } from "react-icons/ai";
import { HiOutlineBuildingLibrary } from "react-icons/hi2";
import { FaLock } from "react-icons/fa";
import TermAndConditionForSubscription from "./TermAndConditionForSubscription";
import { IoClose } from "react-icons/io5";
import { useGoogleLogin } from "@react-oauth/google";
import { setAuth } from "../Redux/Slice/authSlice";
import { store } from "../Redux/store";

const schema = yup
  .object({
    name: yup.string().required(t("pleaseEnterUserName")),
    // email: yup.string().email().required(t("pleaseEnterEmail")),
    phoneNumber: yup.number(),
    company: yup.string(),
    password: yup.string().required(t("pleaseEnterPassword")),
  })
  .required();

function SignUp() {
  const [otpSend, setOtpSend] = useState(false);
  const [enteredOtp, setEnteredOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [sendOtpLoading, setSendOtpLoading] = useState(false);
  const [verifyOtpLoading, setverifyOtpLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isOTPVerified, setIsOTPVerified] = useState(false);
  const [isTermAnsConditionChecked, setIsTermAndConditionChecked] =
    useState(false);
  const [openTermAndCondition, setOpenTermAndCondition] = useState(false);
  const [user, setUser] = useState([]);
  // const [otpSend, setOtpSend] = useState(false); // Tracks if OTP was sent

  const currentLanguage = useSelector(
    (state) => state?.global?.current_language
  );
  const query = new URLSearchParams(window.location.search);
  const projectId = query.get("projectId");
  const id = query.get("invite_id");
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const navigate = useNavigate();
  const type = query.get("type");

  useEffect(() => {
    if (loading && !otpSend) {
      setButtonDisabled(true);
    }
  }, [loading]);

  const onSubmit = async (data) => {
    if (isOTPVerified) {
      setLoading(true);
      const userData = {
        name: data?.name,
        email: email,
        password: data?.password,
        type: type || 0,
        invite_id: id,
        phoneNumber: data?.phoneNumber,
        company: data?.company,
      };
      const response = await signUpService(userData);
      if (response.status === "success") {
        toast.success(t("registeredSuccessFully"));
        navigate(`/signin?projectId=${projectId}`);
        setLoading(false);
      } else {
        toast.error(response.data);
        setLoading(false);
      }
      reset({
        name: "",
        email: "",
        password: "",
        phoneNumber: "",
        company: "",
      });
    } else {
      toast.error("Please Enter Correct OTP!!!");
      return;
    }
  };

  let debounceTimer;

  const sendOtpHandle = async () => {
    if (buttonDisabled) return;
    try {
      setSendOtpLoading(true);
      const response = await sendRegisterOtp({ email: email });
      if (response?.status === "success") {
        toast.success(
          t("OTP has been sent to your email. Use it to complete the login.")
        );
        setOtpSend(response?.returnOtpValue?.otp);
      } else {
        toast.error(response?.data);
      }
    } catch (error) {
      toast.error(t("anErrorOccurredDuringLogin"));
    } finally {
      setSendOtpLoading(false);
    }
  };

  const debouncedSendOtpHandle = (email) => {
    setSendOtpLoading(true);
    // clearTimeout(debounceTimer);
    setTimeout(() => {
      if (isTermAnsConditionChecked) {
        sendOtpHandle(email);
        setSendOtpLoading(false);
        setOtpSend(true);
      } else {
        toast.error("Please accept the terms and conditions");
      }
    }, 2000);
  };

  const verifyOTP = () => {
    setverifyOtpLoading(true);
    if (Number(enteredOtp) == Number(otpSend)) {
      setIsOTPVerified(true);
      setTimeout(() => {
        setverifyOtpLoading(false);
        alert("OTP Verified");
      }, 2000);
      toast.success("OTP Verified, PLease Register");
    } else {
      setIsOTPVerified(false);
      toast.error("Wrong OTP, Correct OTP or Resend OTP");
    }
  };

  const signupWithGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const response = await googleLoginService(tokenResponse?.access_token);
      console.log("Logged in successfully:", response);
      if (response?.token) {
        toast.success("Successfully loggedin");
        navigate("/");
      }
      else{
        toast.error(response?.error);
        }
    },
    onError: (error) => {
      console.error("Google login error:", error);
    },
  });

  return (
    <section className="min-h-[85vh]">
      <div className="custom-bg">
        <div className="h-full flex justify-end items-center">
          <div className="bg-white shadow-lg  w-[100%] md:w-[50%] xl:w-[29%] rounded-[0rem]">
            <div className="text-black flex h-[100vh] items-center justify-center relative">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex justify-center items-center mb-[20px]">
                  {/* md:mb-[35px] */}
                  <img src="/new_logo.svg" width={150} alt="" />
                </div>

                <h5 className="text-center faqs-titlemb-[25px]">
                  {t("pleaseFillTheDetailsBelowToRegister")}
                </h5>
                <>
                  <div className="flex flex-col mt-[20px]">
                    {/* md:mt-[40px] */}
                    <div className={`${errors.name ? "" : "mb-[20px]"}`}>
                      <div className="relative">
                        <input
                          placeholder={t("fullName")}
                          type="text"
                          {...register("name")}
                          style={{ paddingLeft: "45px !important" }}
                          className="form-control-lg"
                        />
                        <div className="input-logo">
                          <img src="/Person.svg" alt="person-logo" />
                        </div>
                      </div>
                      {errors.name && (
                        <span className="text-[red] text-[11px]">
                          {errors.name.message}
                        </span>
                      )}
                    </div>{" "}
                    <div className={`${errors.email ? "" : "mb-[20px]"}`}>
                      <div className="relative">
                        <input
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                          placeholder={t("email")}
                          type="email"
                          // {...register("email")}
                          style={{ paddingLeft: "45px !important" }}
                          className="form-control-lg"
                        />
                        <div className="input-logo">
                          <img src="/email.svg" alt="email-logo" />
                        </div>
                      </div>
                    </div>{" "}
                    <div
                      className={`${
                        errors.phoneNumber ? "mb-[20px]" : "mb-[20px]"
                      }`}
                    >
                      <div className="relative">
                        <input
                          placeholder={t("phoneNumber")}
                          type="number"
                          {...register("phoneNumber")}
                          style={{ paddingLeft: "45px !important" }}
                          className="form-control-lg"
                        />
                        <div className="input-logo">
                          <AiOutlinePhone size={22} />
                        </div>
                      </div>
                    </div>{" "}
                    <div
                      className={`${
                        errors.company ? "mt-[20px]" : "mb-[20px]"
                      }`}
                    >
                      <div className="relative">
                        <input
                          placeholder={t("company")}
                          type="text"
                          {...register("company")}
                          style={{ paddingLeft: "45px !important" }}
                          className="form-control-lg"
                        />
                        <div className="input-logo">
                          <HiOutlineBuildingLibrary size={22} />
                        </div>
                      </div>
                    </div>{" "}
                    <div className={`${errors.password ? "" : "mb-[20px]"}`}>
                      <div className="relative">
                        <input
                          type={showPassword ? "text" : "password"}
                          placeholder={t("password")}
                          {...register("password")}
                          id="form2Example27"
                          className="form-control form-control-lg"
                        />
                        <div className="input-logo">
                          <img src="/password.svg" alt="email-logo" />
                        </div>
                        <div
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                          className="password-input-logo"
                        >
                          {showPassword ? (
                            <img src="/eye-open.svg" alt="email-logo" />
                          ) : (
                            <img src="/eye-off.svg" alt="email-logo" />
                          )}
                        </div>
                      </div>

                      {errors.password && (
                        <span className="text-[red] text-[11px]">
                          {errors.password.message}
                        </span>
                      )}
                    </div>
                    <div className="relative flex gap-[20px] mb-[20px] md:mb-[25px]">
                      {/* md:mb-[50px] */}
                      <input
                        type="checkbox"
                        className="h-[18px]"
                        checked={watch("tANDc")}
                        onChange={(e) => {
                          setIsTermAndConditionChecked(e.target.checked);
                          setValue("tANDc", e.target.checked);
                        }}
                      />
                      <p className="text-[11px] font-[500] ">
                        {t("iUnderstandAndAgreeTo")}{" "}
                        <span
                          className="cursor-pointer"
                          onClick={() => setOpenTermAndCondition(true)}
                        >
                          <b>{t("privacyPolicy1")}</b> {t("and")}{" "}
                          <b>{t("termsOfUse")}</b>
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className="mb-4 flex justify-center gap-5">
                    <div className="relative">
                      <input
                        placeholder={"Enter OTP"}
                        type="text"
                        maxLength={6}
                        style={{ paddingLeft: "45px !important" }}
                        className="form-control-lg"
                        value={enteredOtp}
                        onChange={(e) => setEnteredOtp(e.target.value)}
                      />
                      <div className="input-logo">
                        <FaLock />
                      </div>
                    </div>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        if (enteredOtp.length === 6) {
                          verifyOTP();
                        } else {
                          debouncedSendOtpHandle(email);
                        }
                      }}
                      disabled={
                        sendOtpLoading || (!otpSend && enteredOtp.length === 6)
                      }
                      className={`px-[10px] py-[10px] border-transparent bg-[#0072BB] text-[#fff] font-[600] rounded-md ${
                        buttonDisabled ? "cursor-not-allowed" : ""
                      }`}
                    >
                      {sendOtpLoading
                        ? "Loading..."
                        : enteredOtp.length === 6
                        ? verifyOtpLoading
                          ? "Verifying..."
                          : "Verify OTP"
                        : "Send OTP"}
                    </button>
                  </div>

                  {
                    <>
                      <div
                        className={`flex justify-between border-b pb-[20px] mb-[20px] `}
                      >
                        <button
                          className={`${
                            !isOTPVerified ? "cursor-not-allowed" : ""
                          }	`}
                          disabled={!isOTPVerified}
                          type="submit"
                        >
                          {loading ? "Loading..." : t("createAccount")}
                        </button>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            signupWithGoogle();
                          }}
                          className="flex justify-center items-center gap-3 border px-3 py-2 border-blue-500 rounded-md"
                        >
                          <p className="font-semibold"> Register with</p>
                          <img className="w-5" src="./google.svg" />
                        </button>
                      </div>
                    </>
                  }

                  <div className="text-center">
                    <p className="text-[14px] font-[500] mb-[5px]">
                      {t("haveAnAccount")}
                    </p>
                    <Link
                      className="button-text text-[#0072BB] underline"
                      to={"/signin"}
                    >
                      {t("loginHere")}
                    </Link>
                  </div>
                </>
              </form>
            </div>
          </div>
        </div>
        {openTermAndCondition && (
          <div className="fixed inset-0 flex justify-center items-center bg-gray-400 bg-opacity-50  z-40">
            <div className="bg-white rounded-lg p-5 w-100 h-[800px] absolute min-w-[50vw] min-h-[50vh] overflow-auto mx-10">
              <IoClose
                onClick={() => setOpenTermAndCondition(false)}
                className="absolute top-[3%] right-[4%] text-[26px] cursor-pointer rounded-full border font-bold"
              />

              <div className="flex flex-row flex-wrap mt-6 ">
                <TermAndConditionForSubscription
                  footer={true}
                  header={true}
                  topSection={true}
                  setOpenTermAndCondition={setOpenTermAndCondition}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default SignUp;
